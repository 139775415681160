/// <reference path='../_reference.ts' />
module Apex.RsService.Controllers {

    import IAuthMofUser = Apex.RsService.Interfaces.IAuthMofUser;
    import IInvoice = Apex.RsService.Interfaces.IInvoice;
    export class InvoiceBooks extends Core.Controller {

        public static $inject = [
            Globals.scope, 'accountService', 'rSService', 'dialogs', '$filter', '$rootScope', Globals.translate, Globals.toaster
        ];

        private currencies = [];

        constructor(private $scope: any, private accountService: Account.Services.Account, private rSService: Services.RS, private dialogs: any, private $filter: any, private $rootScope, private $translate: any, private toaster: any) {

            super(arguments, InvoiceBooks.$inject);

            $scope.events = this;

            $scope.auth = {
                authenticated: false
            };

            $scope.mofDictionary = {
                kTypes: []
            };

            this.getMofDicionary();


            $scope.invoiceBookGridOptions = this.invoiceBookGridOptions();

            var yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);

            $scope.filters = {
                dateBetween: {
                    fromDate: yesterday,
                    toDate: new Date()
                },
                onlyUpload: 0
            };

            var dataSourceIsLoaded = false;

            var openLookupEvent = () => {
                if (!dataSourceIsLoaded) {
                    accountService.getAccounts(13).then(a => {
                        $scope.debetAccnOptions.dataSource.data(a.Result);
                        return "";
                    });
                    dataSourceIsLoaded = true;
                }
            };


            $scope.debetAccnOptions = {
                valueText: 'AccWithName',
                columns: this.devetCreditColumns,
                dataSource: new kendo.data.DataSource({
                    data: [], schema: {
                        model: {
                            fields: {
                                Acc: { type: "string" },
                                Name: { type: "string" }
                            }
                        }
                    }
                }),
                openEvent: openLookupEvent,
            };

            $scope.changeSelected = selectedBook => {
                var focusedRowModel: IInvoice = selectedBook;
                $scope.selectedBook = focusedRowModel;
            };

            var timeoutCode;
            var delayInMs = 2000;

            $scope.$watch('filters', (filterVal) => {

                if (!filterVal[1])
                    return;

                clearTimeout(timeoutCode);  //does nothing, if timeout already done

                timeoutCode = setTimeout(() => {
                    $scope.events.reloadGridData(filterVal[0]);
                }, delayInMs);
            }, true);

            this.allowedOpersDS();

            $rootScope.$on('updateGrid', () => {
                $scope.events.reloadGridData($scope.filters);
            })

            this.getCurrency();
        }

        devetCreditColumns: Array<Core.Interfaces.IGridColumn> = [
            { field: 'Acc', titleTranslated: 'acc', width: 110 },
            { field: 'Name', titleTranslated: 'nameCaption' }
        ];

        allowedOpersDS() {
            this.accountService.getOpers().then((response) => {
                var acc = [{ OperID: '', templateName: this.$translate.instant('AllOperations') }];
                _.each(response.Result, (x: any) => {
                    x.templateName = x.OperID + ' - ' + x.Name;
                    acc.push(x);
                });
                this.$scope.allowedOpers = _.filter(response.Result, (x: any) => (typeof x.WebFormName !== "undefined"));
                this.$scope.allOpers = acc;
            });
        }

        getCurrency = () => {
            this.accountService.getCurrency().then(result => {
                _.each(result.Result, (x: any) => {
                    x.textTemplate = x.Code + ' - ' + x.Name;
                });
                this.currencies = result.Result;
                return "";
            });
        }

        refresh() {
            this.$scope.events.reloadGridData(this.$scope.filters);
        }

        importFNumber() {
            var dlg = this.dialogs.confirm(this.$translate.instant('Attention'), this.$translate.instant('confirmImportFNumbers'));
            var filter = this.$scope.filters;
            dlg.result.then(btn => {
                this.rSService.importFNumber(filter.dateBetween.fromDate, filter.dateBetween.toDate, false).then(results => {
                    this.doAfterAction(results, "ImportFNumber");
                });
            });
        }

        send(selectedBook) {
            if (typeof selectedBook === "undefined")
                return;
            var dlg = this.dialogs.confirm(this.$translate.instant('Attention'), this.$translate.instant('sendInvoice'));
            dlg.result.then(btn => {
                this.rSService.getPayerTypeFromBookID(selectedBook.Book_id).then(results => {
                    if (!results.Result) {
                        var dlg = this.dialogs.confirm(this.$translate.instant('Attention'), this.$translate.instant('sendInvoiceForNotVatPayer'));
                        dlg.result.then(btn => {
                            this.rSService.createInvoice(selectedBook.Book_id).then(results => {
                                this.doAfterAction(results, "InvoiceSend");
                                return "";
                            });
                        });
                    } else {
                        this.rSService.createInvoice(selectedBook.Book_id).then(results => {
                            this.doAfterAction(results, "InvoiceSend");
                            return "";
                        });
                    }
                    return "";
                });
            });
        }

        deleteLocal(selectedBook) {
            if (typeof selectedBook === "undefined")
                return;
            var dlg = this.dialogs.confirm(this.$translate.instant('Attention'), this.$translate.instant('deleteLocalInvoice'));
            dlg.result.then(btn => {
                this.rSService.deleteLocalInvoice(selectedBook.Book_id, selectedBook.MofInvoiceNum).then(results => {
                    this.doAfterAction(results, "InvoiceDelete");
                    return "";
                });

            });
        }

        correct(kType) {
            var selectedBook = this.$scope.selectedBook;
            if (typeof selectedBook === "undefined")
                return;

            var filter = this.$scope.filters;

            var formData: any = {
                Invoice: {},
                correcting: true
            };

            this.rSService.makeKInvoice(selectedBook.MofInvoiceNum, kType.ID).then((result) => {
                formData.Invoice = result.Invoice;
                this.reloadGridData(filter);
                var dlg = Core.Utils.ModalForm.openModalForm("modal.invoice.html", Controllers.InvoiceEdit.getName(), formData, Core.Utils.ModalSize.Medium);
                dlg.result.then(result => {
                    if (typeof result !== "undefined")
                        this.reloadGridData(filter);
                });


                return "";
            });
        }

        details(selectedBook) {
            if (typeof selectedBook === "undefined")
                return;

            var filter = this.$scope.filters;

            var formData: any = {
                Invoice: {}
            };

            this.rSService.getInvoiceView(selectedBook.MofInvoiceNum).then((result: any) => {
                formData.Invoice = result.Invoice;
                var dlg = Core.Utils.ModalForm.openModalForm("modal.invoice.html", Controllers.InvoiceEdit.getName(), formData, Core.Utils.ModalSize.Medium);
                dlg.result.then(result => {
                    if (typeof result !== "undefined")
                        this.reloadGridData(filter);
                });
            });
        }

        doAfterAction(results, actionName) {
            var filter = this.$scope.filters;
            if (results && results.Error === 0) {
                delete this.$scope.selectedBook;
                this.toaster.pop('success', this.$translate.instant('Info'), this.$translate.instant('success' + actionName));
                this.reloadGridData(filter);
            } else if (!results.success) {
                delete this.$scope.selectedBook;
                this.toaster.pop('error', "Error", this.$translate.instant('fail' + actionName));
            }
        }

        editBook(selectedBook) {
            Account.Utils.Documents.openDoc(selectedBook.Docs_id, false);
        }

        reloadGridData(filter) {
            var data = {
                D1: new Date(filter.dateBetween.fromDate.setHours(0, 0, 0, 0)),
                D2: new Date(filter.dateBetween.toDate.setHours(23, 59, 59, 999)),
                Client: null,
                Numberin: null,
                Numberout: null,
                OperId: null,
                Cuser: null,
                Iud: null
            };

            if (filter.oper) {
                data.OperId = filter.oper;
            }

            if (filter.numberIn) {
                data.Numberin = filter.numberIn;
            }

            if (filter.numberOut) {
                data.Numberout = filter.numberOut;
            }

            //data.Iud = filter.onlyUpload;

            if (filter.debet) {
                data.Client = filter.debet.Acc;
            }
            this.rSService.getInvoiceBooks(data).then((result: any) => {
                if (typeof result === "undefined") {
                    return;
                }
                for (var i = 0; i < result.BookList.length; i++) {
                    result.BookList[i].OperType = this.getOperName(result.BookList[i].Oper_id);
                }
                this.$scope.invoiceBookGridOptions.update(result.BookList);
                return "";
            });
        }

        getOperName(operId) {
            for (var i = 0; i < this.$scope.allOpers.length; i++) {
                if (this.$scope.allOpers[i].OperID == operId) {
                    return this.$scope.allOpers[i].templateName;
                }
            }

        }

        private templates: Array<Core.Interfaces.IGridColumnTemplate> = [
            {
                fieldName: 'SendTime',
                template: '<div class="text-center" ng-if="dataItem.MofID"><span class="glyphicon glyphicon-ok"></span></div>'
            },
            {
                fieldName: 'Blocking',
                template: '<div class="text-center" ng-if="dataItem.Blocking"><img src="./img/lock.png" width="16"></div>'
            },
            {
                fieldName: 'RecStatus',
                template: '<div class="text-center" ng-if="dataItem.RecStatus"><img src="./img/fx.png" width="16"></div>'
            }
        ];

        private invoiceGridColumns: Array<Core.Interfaces.IGridColumn> = [
            { field: 'Blocking', titleTranslated: 'Blocking', width: 32, filterable: false, objectType: 'boolean' },
            { field: 'RecStatus', titleTranslated: 'status', width: 32, filterable: false, objectType: 'boolean' },
            { field: 'DDate', titleTranslated: 'DATE', width: 100, format: Core.Kendo.Format.Date },
            { field: 'OperType', titleTranslated: 'operType', width: 140 },
            { field: 'DB', titleTranslated: 'sender', width: 140 },
            { field: 'NumberIn', titleTranslated: 'numberin', width: 80 },
            { field: 'NumberOut', titleTranslated: 'numberout', width: 80 },
            { field: 'Vat', titleTranslated: 'vat', width: 100, format: Core.Kendo.Format.Price2 },
            { field: 'FullVg', titleTranslated: 'amount', width: 100, format: Core.Kendo.Format.Price2 },
            { field: 'CUser', titleTranslated: 'cuser', width: 60 },
            { field: 'Flag', titleTranslated: 'status', width: 60 }
        ];

        private invoiceBookGridOptions(): Components.IApxGrid {
            var self = this;

            var dataSourceConfig = <kendo.data.DataSourceOptions>{
                pageSize: 50,
                schema: {
                    model: {
                        fields: {
                            // Blocking: { type: 'string' },
                            // RecStatus: { type: 'string' },
                            DDate: { type: 'date' },
                            // OperType: { type: 'string' },
                            DB: { type: 'string' },
                            NumberIn: { type: 'number' },
                            NumberOut: { type: 'number' },
                            Vat: { type: 'string' },
                            FullVg: { type: 'number' },
                            CUser: { type: 'string' },
                            // Flag: { type: 'string' },
                        }
                    }
                }
            };

            var gridOptions: Components.IApxGrid = <Components.IApxGrid>{
                columns: this.invoiceGridColumns,
                templates: this.templates,
                gridEvents: [this.getChangeEvent()],
                sortable: true,
                selectable: "single",
                resizable: true,
                filterable: {
                    mode: "row"
                },
                settings: {
                    virtualScroll: true
                },
                parentScope: this.$scope,
                dataSource: new kendo.data.DataSource(dataSourceConfig),
                update: function (newDataSource) {
                    this.dataSource.data(newDataSource);
                },
            };

            return gridOptions;
        }

        private getChangeEvent(): Components.IEvent {
            var changeEventType: Components.EventType = Components.EventType.Change;
            var addEvent: Components.IEvent =
                {
                    eType: changeEventType,
                    eFunction: this.changeEventFunctionality
                };
            return addEvent;
        }

        changeEventFunctionality = (e) => {
            Core.Utils.Helper.safeApply(this.$scope, this.$scope.changeSelected(e.sender.dataItem(e.sender.select())));
        };

        getMofDicionary() {
            this.rSService.getWBMOFDicionary(9).then((result: any) => {
                this.$scope.mofDictionary.kTypes = result.Result;
                return " ";
            });
        }

    }
}
