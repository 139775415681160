/// <reference path='../../_reference.ts' />
module Apex.RsService.Controllers {


    import IWayBill = Apex.RsService.Interfaces.IWayBill;
    import IMOFDictionary = Apex.RsService.Interfaces.IMOFDictionary;
    import IIncomeDocTemplate = Apex.RsService.Interfaces.IIncomeDocTemplate;
    import IIncomeProduct = Apex.RsService.Interfaces.IIncomeProduct;
   

    export interface WaybillIncomeScope extends angular.IScope {
        incomeModel: IIncomeDocTemplate;
        productsGrid: Components.IApxGrid;
        components: any;
        save: Function;
        saveAccordance: Function;
        fillProdPP: Function;
        makeFormDirty: Function;
        closePopup: Function;
        validateSave: Function;
        recalculateVat: Function;
        updateProduct: Function;
        isDirty: boolean;
        productLookup: Components.IApxLookup;
        selectedProduct: IIncomeProduct;
        showColumns: any;
        selectedProductProduct: IIncomeProduct;
        filters: any;
    }

    export class WaybillIncome extends Core.Controller {
        public static $inject = [
            Globals.scope, 'data', 'accountService', 'rSService', Globals.modalInstance, Globals.translate, Globals.toaster, Globals.http, '$timeout',
        ];
        constructor(private $scope: WaybillIncomeScope, data: any, private accountService: Account.Services.Account, private rSService: Services.RS,
            private $uibModalInstance, private $translate: any, private toaster, private $http: angular.IHttpService, $timeout:any) {
            super(arguments, WaybillIncome.$inject);
            $scope.incomeModel = data.IncomeDocTemplate;
            $scope.showColumns = {
                val: false
            };

            $scope.isDirty = false;

            $scope.filters = {
                priceType: 0,
                costType: 0
            };

            var findAccounts = () => {
                this.accountService.getAccountsByOperID($scope.incomeModel.OperID).then(a => {
                    $scope.components.debet.dataSource = a.DBAccounts.toDatasource();
                    var crDs = _.filter(a.CRAccounts, (x: any) => (x.SN == $scope.incomeModel.SupplierSN));
                    $scope.components.credit.dataSource = crDs.toDatasource();
                    $scope.incomeModel.DB = _.first(a.DBAccounts);
                    $scope.incomeModel.CR = _.first(crDs);
                });
            };

            var allowedOpers = () => {
                this.accountService.getOpers(1024).then((response) => {
                    _.each(response.Result, (x: any) => {
                        x.templateName = x.OperID + ' - ' + x.Name
                    });
                    $scope.components.allowedOpers = _.filter(response.Result, (x: any) => (typeof x.WebFormName !== "undefined"));
                    $scope.incomeModel.OperID = response.Result[0].OperID;
                });
            };

            $scope.components = {
                debet: {
                    valueText: 'AccWithName',
                    columns: this.accountColumns,
                    dataSource: [].toDatasource()
                },
                credit: {
                    valueText: 'AccWithName',
                    columns: this.accountColumns,
                    dataSource: [].toDatasource()
                }
            };

            allowedOpers();

            for (var i = 0; i < $scope.incomeModel.Products.length; i++) {
                this.recalculateByVat($scope.incomeModel.Products[i]);
            }

            $scope.productsGrid = this.productsGridOptions($scope.incomeModel.Products);
            $timeout(() => {
                $scope.productsGrid.gridView.showLoading();
            });

            $scope.productLookup = this.bcDropdownOptions();

            $scope.$watch('incomeModel.OperID', (filterVal, oldValue) => {
                if (angular.equals(filterVal, oldValue)) {
                    return;
                }
                findAccounts();
            });

            $scope.$watch('incomeModel.DB', (filterVal, oldValue) => {
                if (angular.equals(filterVal, oldValue)) {
                    return;
                }
                this.refreshProductParams();
            });

            $scope.$watch('filters.priceType', (filterVal, oldValue) => {
                if (angular.equals(filterVal, oldValue)) {
                    return;
                }
                this.refreshProductParams();
            });



            $scope.$watch('filters.costType', (filterVal, oldValue) => {
                if (angular.equals(filterVal, oldValue)) {
                    return;
                }
                this.refreshProductParams();
            });


            $scope.$watch('productsGrid.grid', (grid) => {
                if (grid != undefined) {
                    $scope.productsGrid.gridView.hideLoading();
                    $scope.productsGrid.grid.hideColumn('VatAmount');
                    $scope.productsGrid.grid.hideColumn('AmountWithoutVat');
                }
            });

            $scope.$watch('showColumns.val', (res) => {
                if ($scope.productsGrid.grid != undefined) {
                    if (res === true) {
                        $scope.productsGrid.grid.showColumn('VatAmount');
                        $scope.productsGrid.grid.showColumn('AmountWithoutVat');
                    } else {
                        $scope.productsGrid.grid.hideColumn('VatAmount');
                        $scope.productsGrid.grid.hideColumn('AmountWithoutVat');
                    }
                }
            });

            $scope.$watch('selectedProductProduct', (filterVal: any, oldValue: any) => {
                if (!$scope.selectedProduct || filterVal==null || angular.equals(filterVal, oldValue)) {
                    return;
                }
                for (var i = 0; i < $scope.incomeModel.Products.length; i++) {
                    if ($scope.incomeModel.Products[i].WbDetailId == $scope.selectedProduct.WbDetailId) {
                        $scope.incomeModel.Products[i].ProductsNu = filterVal.ProductsNu;
                        $scope.incomeModel.Products[i].Unit = filterVal.Unit;
                        $scope.incomeModel.Products[i].BCode = filterVal.BCode;
                        $scope.incomeModel.Products[i].ProducerNu = filterVal.ProducerNu;
                        $scope.incomeModel.Products[i].ProdPPId = filterVal.ProdPPId;
                        this.updateProductParam($scope.incomeModel.Products[i]);
                        this.$scope.productsGrid.grid.dataSource.read();
                        break;
                    }
                }
            });

            $scope.makeFormDirty = this.makeFormDirty;
            $scope.save = this.save;
            $scope.saveAccordance = this.saveAccordance;
            $scope.fillProdPP = this.fillProdPP;
            $scope.closePopup = this.closePopup;
            $scope.validateSave = function () {
                var model = $scope.incomeModel;
                if (model.OperID && model.DB && model.CR) {

                    var products = $scope.productsGrid.grid.dataSource.data();

                    if (products.length == 0) {
                        return false;
                    }

                    for (var i = 0; i < products.length; i++) {
                        if (products[i].LifeDate && products[i].LifeDate.getTime() < $scope.incomeModel.DDate) {
                            return false;
                        }
                    }
                    return true;
                }
                return false;
            };
            $scope.recalculateVat = this.recalculateByVat;

            $(document).keydown(this.closeHandle);
        }

        closeHandle = (e) => {
            if (e.keyCode === 27) {
                this.closePopup();
            }
        }

        accountColumns: Array<Core.Interfaces.IGridColumn> = [
            { field: 'Acc', titleTranslated: 'acc', width: 110 },
            { field: 'Name', titleTranslated: 'nameCaption' }
        ];

        bcDropdownOptions = () => {
            var pProductsLookup: Components.IApxLookup =
                {
                    columns: [
                        { field: 'BCode', width: 105, titleTranslated: 'barcode' },
                        { field: 'ProductsNu', width: 250, titleTranslated: 'caption' },
                        { field: 'Unit', width: 70, titleTranslated: 'unit' },
                        { field: 'ProducerNu', width: 220, titleTranslated: 'producer' }
                    ],
                    dataSource: Core.Kendo.GridView.initAutoQueryDataSource(this.accountService.productsQueryUrl),
                    valueText: 'ProductsNu'
                };

            return pProductsLookup;
        }




        saveAccordance = () => {

        var model = angular.copy(this.$scope.incomeModel);
      
        var products = this.$scope.productsGrid.grid.dataSource.data();

        var translate = Core.Utils.Helper.getServiceFromJs(Globals.translate);

        var filtredproducts = _.filter(products, function (prod: Interfaces.IIncomeProduct) { return prod.ProdPPId != undefined });

        if (filtredproducts.length ==0) {
            this.toaster.pop('error', translate.instant('Attention'), 'შესაბამისობა არ არის დადგენილი არცერთ პროდუქტზე.');
            return;
        }

        this.rSService.updateProductPPRefs(products, this.$scope.incomeModel.SupplierID, this.$scope.incomeModel.SupplierSN).then((result: any) => {
            if (typeof result.Error !== "undefined" && result.Error !== 0) { 
                this.toaster.pop('success', translate.instant('error'), result.ErrorMessage);
                return;
            }
            this.toaster.pop('success', translate.instant('Info'), translate.instant('successUpdateProductPPRefs'));
        });

    };

        private productsGridColumns: any =
        [
            {
                field: 'WbProductCode',
                titleTranslated: 'code',
                locked: true,
                width: 90
            },
            {
                width: 250,
                field: 'WbProductName',
                titleTranslated: 'caption'
            },
            {
                field: 'WbProductUnit',
                titleTranslated: 'unit',
                width: 70
            },
            {
                field: 'BCode',
                titleTranslated: 'barcode',
                width: 80,
                cssClass: 'border-left'
            },
            {
                field: 'Product',
                editable: true,
                titleTranslated: 'caption',
                width: 350
            },
            {
                field: 'Unit',
                titleTranslated: 'unit',
                width: 70
            },          
            {
                field: 'LifeDate',
                titleTranslated: 'lifeDate',
                editable: true,
                format: Core.Kendo.Format.Date,
                twoWayBinding: true,
                width: 75
            },
            {
                field: 'Vat',
                titleTranslated: 'vat',
                twoWayBinding: true,
                editable: true,
                width: 60
            },
            {
                field: 'ItCount',
                titleTranslated: 'Count',
                editable: true,
                format: Core.Kendo.Format.Price4,
                footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.ItCount && data.ItCount.sum ? parseFloat(data.ItCount.sum).toFixed(4) : 0) #',
                width: 80
            },
            {
                field: 'Price',
                titleTranslated: 'price',
                format: Core.Kendo.Format.Price4,
                editable: true,
                width: 80
            },
            {
                field: 'LastCost',
                titleTranslated: 'lastCost',
                format: Core.Kendo.Format.Price4,
                width: 80
            },
            {
                field: 'SalePrice',
                titleTranslated: 'salePrice',
                format: Core.Kendo.Format.Price4,
                width: 80
            },
            {
                field: 'VatAmount',
                titleTranslated: 'vat',
                width: 60,
                format: Core.Kendo.Format.Price4,
                footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.VatAmount && data.VatAmount.sum  ? data.VatAmount.sum.toFixed(4) : 0) # '
            },
            {
                field: 'AmountWithoutVat',
                titleTranslated: 'PriceWithOutVat',
                format: Core.Kendo.Format.Price4,
                footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.AmountWithoutVat && data.AmountWithoutVat.sum  ? data.AmountWithoutVat.sum.toFixed(4) : 0) # ',
                width: 80
            },
            {
                field: 'Amount',
                titleTranslated: 'amount',
                format: Core.Kendo.Format.Price4,
                editable: true,
                width: 85,
                footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.Amount && data.Amount.sum  ? data.Amount.sum.toFixed(4) : 0) # '
            },
              {
                field: 'ProducerNu',
                titleTranslated: 'producer',
                cssClass: 'border-right',
                width: 250
            },
        ];

        private productsGridTempalate: Array<Core.Interfaces.IGridColumnTemplate> = [
            {
                fieldName: 'Vat',
                template: ' <div style="text-align: center;"><span>{{:: dataItem.Vat * 100}}%</span></div>',
                //editor(container, options) {
                //    $('<div class="btn-group">' +
                //        '<label style="font-size: 10px;" class="btn btn-primary" ng-model="dataItem.' + options.field + '" uib-btn-radio="0">0%</label>' + 
                //        '<label style="font-size: 10px;" class="btn btn-primary" ng-model="dataItem.' + options.field + '" uib-btn-radio="0.18">18%</label>' +
                //        '</div>')
                //        .appendTo(container);
                //},
                editor: (container: JQuery, options: any) => {
                    $('<input data-text-field="VatText" data-value-field="Id" data-bind="value:' + options.field + '"  ng-model = "dataItem.' + options.field + '"/>')
                        .appendTo(container)
                        .kendoDropDownList({
                            dataSource: [{ Id: 0, VatText: '0%' }, { Id: 0.18, VatText: "18%" }],
                            dataTextField: "VatText",
                            dataValueField: "Id"
                        });
                }
            },
            {
                fieldName: 'Product',
                template: '<span>{{:: dataItem.ProductsNu }}</span>',
                editor(container, options) {
                    $(`<button  style="float:left;" type="button" onclick="Apex.Dictionary.Utils.ProdppRegistration.createProdpp()">+</button>                      
                     <apx-lookup style="width:550px;" ng-model="dataItem.` + options.field + `" model="options.parentScope.selectedProductProduct" options="options.parentScope.productLookup"></apx-lookup>`)
                        .appendTo(container);
                }
            },
            {
                fieldName: 'Unit',
                template: '<span>{{:: dataItem.Unit }}</span>'
            },
            {
                fieldName: 'BCode',
                template: '<span>{{:: dataItem.BCode }}</span>'
            },
            {
                fieldName: 'ProducerNu',
                template: '<span>{{:: dataItem.ProducerNu }}</span>'
            },
            {
                fieldName: 'LifeDate',
                editor(container, options) {
                    $('<apx-date preventDefaultValueOnFocusOut ng-model="dataItem.' + options.field + '"></apx-date>')
                        .appendTo(container);
                }
            },
            {
                editformat: "{0:##.####}",
                fieldName: 'ItCount',
                editor: function (container, options) {

                    $("<input name='" + options.field + "' id=countInput  />")
                        .appendTo(container)
                        .kendoNumericTextBox({
                            decimals: 4,
                            spinners: false,
                            min: 0
                        });
                }
            },
            {
                editformat: "{0:##.####}",
                fieldName: 'Price',
                editor: function (container, options) {

                    $("<input name='" + options.field + "'/>")
                        .appendTo(container)
                        .kendoNumericTextBox({
                            decimals: 4,
                            spinners: false,
                            min: 0
                        });
                }
            },
            {
                fieldName: 'LastCost',
                template: '<span ng-style="dataItem.LastCost > dataItem.Price && {color:\'green\'} || dataItem.LastCost < dataItem.Price && {color:\'red\'}" ng-if="!dataItem.updating && dataItem.LastCost">{{:: dataItem.LastCost.toFixed(4) }}</span>' +
                '<span ng-if="dataItem.updating" class="k-icon k-loading"></span>'
            },
            {
                fieldName: 'SalePrice',
                template: '<span ng-style="dataItem.SalePrice > dataItem.Price && {color:\'green\'} || dataItem.SalePrice < dataItem.Price && {color:\'red\'}" ng-if="!dataItem.updating && dataItem.SalePrice">{{:: dataItem.SalePrice.toFixed(4) }}</span>' +
                '<span ng-if="dataItem.updating" class="k-icon k-loading"></span>'
            },
            {
                editformat: "{0:##.####}",
                fieldName: 'Amount',
                editor: function (container, options) {

                    $("<input name='" + options.field + "'/>")
                        .appendTo(container)
                        .kendoNumericTextBox({
                            decimals: 4,
                            spinners: false,
                            min: 0
                        });
                }
            }
        ];

        private productsGridOptions(dataSource: any): Components.IApxGrid {
            var gridOptions: Components.IApxGrid = {
                columns: this.productsGridColumns,
                templates: this.productsGridTempalate,
                dataSource: new kendo.data.DataSource({
                    data: dataSource,
                    aggregate: [
                        { field: "Amount", aggregate: "sum" },
                        { field: "ItCount", aggregate: "sum" },
                        { field: "AmountWithoutVat", aggregate: "sum" },
                        { field: "VatAmount", aggregate: "sum" },
                        { field: "ItCount", aggregate: "count" }
                    ],
                    schema: {
                        model: {
                            id: "WbDetailId",
                            fields: {
                                WbProductCode: { editable: false },
                                WbProductName: { editable: false },
                                WbProductUnit: { editable: false },
                                BCode: { editable: false },
                                Unit: { editable: false },
                                ProducerNu: { editable: false },
                                LastCost: { editable: false },
                                SalePrice: { editable: false },
                                VatAmount: { editable: false },
                                AmountWithoutVat: { editable: false }
                            }
                        }
                    }
                }),
                settings: {
                    editble: {
                        disableEditRow: false
                    },
                    height: 550
                },
                parentScope: this.$scope,
                gridEvents: [
                    this.getEditEvent(),
                    this.getChangeEvent()
                ]
            };
            return gridOptions;
        }

        private getChangeEvent(): Components.IEvent {
            var changeEventType: Components.EventType = Components.EventType.Change;
            var addEvent: Components.IEvent =
                {
                    eType: changeEventType,
                    eFunction: this.changeEventFunctionality
                };
            return addEvent;
        }

        changeEventFunctionality = (e) => {
            var focusedRowModel: IIncomeProduct = e.sender.dataItem(e.sender.select());

            if (focusedRowModel == null)
                return;

            for (var i = 0; i < this.$scope.incomeModel.Products.length; i++) {
                if (this.$scope.incomeModel.Products[i].WbDetailId === focusedRowModel.WbDetailId) {
                    if (this.$scope.incomeModel.Products[i].Vat != focusedRowModel.Vat) {
                        this.recalculateByVat(this.$scope.incomeModel.Products[i], focusedRowModel.Vat);
                        e.sender.dataSource.read();
                        this.makeFormDirty();
                    }
                    this.$scope.selectedProduct = this.$scope.incomeModel.Products[i];
                }
            }
        };

        getEditEvent(): Components.IEvent {
            var editEventType: Components.EventType = Components.EventType.Save;
            var editEvent: Components.IEvent =
                {
                    eType: editEventType,
                    eFunction: this.editEventFunctionality
                };
            return editEvent;
        }

        editEventFunctionality = (e) => {
            var focusedRowModel: IIncomeProduct = e.model;
            this.calculationOnEditEvent(e.values, focusedRowModel);
            for (var i = 0; i < this.$scope.incomeModel.Products.length; i++) {
                if (this.$scope.incomeModel.Products[i].WbDetailId === focusedRowModel.WbDetailId) {                   
                    angular.copy(focusedRowModel, this.$scope.incomeModel.Products[i]);
                    e.sender.dataSource.sync();
                }
            }
        };

        private calculationOnEditEvent(changedValues: any, model: IIncomeProduct): void {
            if (changedValues.ItCount != undefined) {
                model.Price = changedValues.ItCount > 0 ? parseFloat((model.Amount / changedValues.ItCount).toFixed(4)) : 0;
                model.ItCount = changedValues.ItCount;
            }
            else if (changedValues.Price != undefined) {
                model.Amount = parseFloat((changedValues.Price * model.ItCount).toFixed(4));
                model.Price = changedValues.Price;
            }
            else if (changedValues.Amount != undefined) {
                model.Price = model.ItCount > 0 ? parseFloat((changedValues.Amount / model.ItCount).toFixed(4)) : 0;
                model.Amount = changedValues.Amount;
            }

            this.recalculateByVat(model);
            this.makeFormDirty();
            return;
        }

        recalculateByVat = (model: IIncomeProduct, change?) => {
            if (!model) {
                return;
            }

            if (typeof change !== 'undefined') {
                model.Vat = change;
            }

            if (model.Vat == 0) {
                model.VatAmount = 0;
                model.AmountWithoutVat = model.Amount;
            } else {
                model.AmountWithoutVat = parseFloat((model.Amount / (1 + model.Vat)).toFixed(4));
                model.VatAmount = parseFloat((model.Amount - model.AmountWithoutVat).toFixed(4));
            }
        };

        save = () => {
            var model = angular.copy(this.$scope.incomeModel);

            model.CR = (<Account.Interfaces.IAccountInfo>model.CR).Acc;
            model.DB = (<Account.Interfaces.IAccountInfo>model.DB).Acc;

            var products = this.$scope.productsGrid.grid.dataSource.data();

            for (var i = 0; i < products.length; i++) {
                for (var i = 0; i < model.Products.length; i++) {
                    if (model.Products[i].WbDetailId === products[i].WbDetailId) {
                        model.Products[i].LifeDate = products[i].LifeDate;
                    }
                }
            }

            var translate = Core.Utils.Helper.getServiceFromJs(Globals.translate);

            if (_.some(products, function (prod: Interfaces.IIncomeProduct) { return prod.ProdPPId == undefined })) {
                this.toaster.pop('error', translate.instant('Attention'), 'შესაბამისობა არ არის დადგენილი ყველა პროდუქტზე.');
                return;
            }

            this.rSService.updateProductPPRefs(products, this.$scope.incomeModel.SupplierID, this.$scope.incomeModel.SupplierSN).then((result: any) => {
                if (typeof result.Error !== "undefined" && result.Error !== 0) {
                    this.rSService.getError(result.Error);
                    return;
                }
                this.toaster.pop('success', translate.instant('Info'), translate.instant('successUpdateProductPPRefs'));

                this.rSService.saveIncomeReflection(model).then(result => {
                    if (typeof result.Error !== "undefined" && result.Error !== 0) {
                        this.rSService.getError(result.Error);
                        return;
                    }
                    this.toaster.pop('success', translate.instant('Info'), 'დოკუმენტი შეინახა');
                    this.$uibModalInstance.close(result);
                });

            });

        };

        fillProdPP = () => {
            var products = this.$scope.incomeModel.Products;
            for (var i = 0; i < products.length; i++) {
                this.updateProductPP(products[i]);
            }
        };

        closePopup = () => {
            if (this.$scope.isDirty) {
                var r = Core.Utils.ModalForm.openConfirmation("გსურთ ცვლილებების გაუქმება?");
                r.result.then(() => {
                    this.$scope.isDirty = false;
                    this.$uibModalInstance.close();
                    $(document).unbind('keydown', this.closeHandle);
                });
            } else {
                this.$scope.isDirty = false;
                this.$uibModalInstance.close();
                $(document).unbind('keydown', this.closeHandle);
            }
        };

        product: IIncomeProduct = {};

        refreshProductParams = () => {
            var products = this.$scope.incomeModel.Products;
            for (var i = 0; i < products.length; i++) {
                this.updateProductParam(products[i]);
            }
        };

        updateProductParam = (product) => {
            if (product && product.BCode && this.$scope.incomeModel.DB) {
                product.updating = true;
                this.$scope.productsGrid.grid.dataSource.read();
                this.rSService.GetProductParams(product.ProdPPId,
                    this.$scope.filters.priceType,
                    this.$scope.filters.costType == 1 ? (<Account.Interfaces.IAccountInfo>this.$scope.incomeModel.DB).Acc : null,
                    this.$scope.incomeModel.CR ? (<Account.Interfaces.IAccountInfo>this.$scope.incomeModel.CR).Acc : null).then((result: any) => {
                        product.LastCost = result.LastCost;
                        product.SalePrice = result.SalePrice;
                        product.updating = false;
                        this.$scope.productsGrid.grid.dataSource.read();
                        return '';
                    });
            }
        };

        makeFormDirty = (e?) => {
            if (e == undefined) {
                if (this.$scope.isDirty === false) {
                    this.$scope.isDirty = true;
                }
            } else {
                if (this.$scope.isDirty === false && e.keyCode !== 13 && e.keyCode !== 27) {
                    this.$scope.isDirty = true;
                }
            }
        };

        updateProductPP(product: IIncomeProduct):void {
            if (!product.ProdPPId) {
                this.rSService.fillProdPP(product).then((result: any) => {
                    if (result.Product) {
                        product.ProdPPId = result.Product.ProdPPId;
                        product.BCode = result.Product.BCode;
                        product.InCode = result.Product.InCode;
                        product.ProductsNu = result.Product.ProductsNu;
                        product.ProducerNu = result.Product.ProducerNu;
                        product.Unit = result.Product.Unit;
                        this.$scope.productsGrid.grid.dataSource.read();
                    }
                    return '';
                });
            }
        };
    }

}