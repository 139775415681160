/// <reference path="./../_reference.ts"/>
module Apex.RsService.Interfaces {
    "use strict";
    export interface IWayBill {
        ID?: number;
        Docs_id?: string;
        Num?: string;
        WbType?: number;
        Status?: number;
        CreateDate?: Date;
        ReceivedDocsID?: string;
        BeginDate?: Date;
        ActivateDate?: Date;
        DeliveryDate?: Date;
        CloseDate?: Date;

        BuyerSN?:string;
        CheckBuyer?:number;
        BuyerName?:string;

        SellerSN?:string;
        SellerName?:string;

        StartAddress?: string;
        EndAddress?: string;
        DriverSN?: string;
        CheckDriverSN?: number;
        DriverName?: string;
        TransportCost?: number;
        ReceptionInfo?: string;
        ReceiverInfo?: string;
        TransporterName?:string;
        TransporterSN?:string;

        ParentID?:number;
        FullAmount?:number;

        CarNumber?:string;
        WaybillNum?:string;
        UserID?:number;
        SellerID?:number;
        TransCostPayer?:number;
        TransportID?:number;
        TransportText?:string;
        Comment?:string;
        IsConfirmed?:boolean;
        IsCorrected?:boolean;
        ConfirmationDate?:Date;

        Goods?: any;
        SubWayBills?:any;
    }
} 