/// <reference path='../../_reference.ts' />
module Apex.RsService.Controllers {


    import IWayBill = Apex.RsService.Interfaces.IWayBill;
    import IIncomeDocTemplate = Apex.RsService.Interfaces.IIncomeDocTemplate;

    export interface WaybillEditScope extends angular.IScope {
        wayBill: IWayBill;
        wayBillBuff: IWayBill;
        state: string;
        modalInstance: any;
        formName: string;
        saveWB: Function;
        closePopup: Function;
        editWB: Function;
        createIncome: Function;
        viewDoc: Function;
        historyWB: Function;
        goodsGridOptions: Core.Interfaces.IGridOptions;
        subWaybillsGridOptions: Components.IApxGrid;
        isDisabled: boolean;
        mofDictionary: any;
        payerType: number;
        goodsTempData?:any
        goodsOldData?:any
        disableSave:boolean
        disableEdit:boolean
        viewMode:boolean
        WbCorrectionList?:any
        tempCorrection?:any
        waybillLookup: Components.IApxLookup;
        helperBeginDate: Date;
        helperRecieveDate: Date;
        reflectedDocId:string;
    }

    export class WaybillEdit extends Core.Controller {
        public static $inject = [
            Globals.scope, 'data', 'rSService', Globals.modalInstance, Globals.translate, Globals.toaster, Globals.http, Globals.filter,'$timeout'
        ];

        $scope:WaybillEditScope;
        rSService:Services.RS;
        $uibModalInstance:any;
        toaster:any;

        constructor($scope:WaybillEditScope, data:any, rSService:Services.RS, $uibModalInstance, $translate:any, toaster, private $http:angular.IHttpService, private $filter:any, private $timeout) {
            super(arguments, WaybillEdit.$inject);

            this.$scope = $scope;
            this.rSService = rSService;
            this.$uibModalInstance = $uibModalInstance;
            this.toaster = toaster;

            $scope.mofDictionary = data.mofDictionary;
            $scope.wayBill = data.WayBill;
            $scope.wayBillBuff = null;
            $scope.formName = $translate.instant('waybill_status' + data.WayBill.Status);
            $scope.isDisabled = $scope.wayBill.Status > 0;
            $scope.goodsGridOptions = this.goodsGridOptions($scope.wayBill.Goods);
            $scope.subWaybillsGridOptions = this.subWaybillsGridOptions($scope.wayBill.SubWayBills);
            $scope.WbCorrectionList = [];
            $scope.tempCorrection = {
                data: null
            };

            $scope.state = data.state;
            if (!$scope.state) {
                $scope.disableSave = $scope.wayBill.ID > 0;
                $scope.disableEdit = $scope.wayBill.ID == 0;
            } else {
                $scope.disableSave = true;
                $scope.disableEdit = !$scope.wayBill.Docs_id;
            }

            $scope.helperBeginDate = $filter('dateFormat')($scope.wayBill.BeginDate, "dd/MM/yyyy HH:mm:ss");
            $scope.helperRecieveDate = $filter('dateFormat')($scope.wayBill.DeliveryDate, "dd/MM/yyyy HH:mm:ss");

            $scope.$watch('wayBill.WbType', (filterVal:any, oldValue) => {
                if (!filterVal || parseInt(filterVal) === parseInt(oldValue)) {
                    return;
                }
                rSService.createWayBillFromDoc(filterVal, $scope.wayBill.Docs_id).then((result) => {
                    if (result.Waybill) {
                        $scope.wayBill = result.Waybill;
                    } else {
                        //   todo show error
                    }
                    return "";
                });
            });

            var firstCall = true;

            $scope.$watch('wayBill.BuyerSN', (filterVal:any, oldValue:any) => {
                if (angular.equals(filterVal, oldValue)) {
                    if (!!filterVal && firstCall) {
                        rSService.getPayerTypeFromSN(filterVal).then((result) => {
                            if (result.Result !== "undefined") {
                                $scope.payerType = result.Result;
                            }
                            return "";
                        });
                        firstCall = false;
                    }
                    return;
                }
                if (filterVal && (filterVal.length === 9 || filterVal.length === 11)) {
                    rSService.getNameFromSN(filterVal).then((result) => {
                        if (result.Result) {
                            $scope.wayBill.BuyerName = result.Result;
                        } else if ($scope.wayBill.CheckBuyer) {
                            $scope.wayBill.BuyerName = '';
                        }
                        return "";
                    });
                    rSService.getPayerTypeFromSN(filterVal).then((result) => {
                        if (typeof result.Error !== "undefined" && result.Error !== 0) {
                            this.rSService.getError(result.Error);
                            return;
                        }

                        if (result.Result !== "undefined") {
                            $scope.payerType = result.Result;
                        }
                        return "";
                    });
                } else {
                    $scope.wayBill.BuyerName = '';
                }
            });

            $scope.$watch('wayBill.TransporterSN', (filterVal:any, oldValue:any) => {
                if (angular.equals(filterVal, oldValue)) {
                    return;
                }
                if (filterVal && (filterVal.length == 9 || filterVal.length == 11)) {
                    rSService.getNameFromSN(filterVal).then((result) => {
                        if (result.Result) {
                            $scope.wayBill.TransporterName = result.Result;
                        }
                        return "";
                    });
                }
            });

            $scope.$watch('wayBill.DriverSN', (filterVal:any, oldValue:any) => {
                if (angular.equals(filterVal, oldValue)) {
                    return;
                }

                if (filterVal && (filterVal.length == 9 || filterVal.length == 11)) {
                    rSService.getNameFromSN(filterVal).then((result) => {
                        if (result.Result) {
                            $scope.wayBill.DriverName = result.Result;
                        } else if ($scope.wayBill.CheckDriverSN) {
                            $scope.wayBill.DriverName = '';
                        }

                        return "";
                    });
                } else {
                    $scope.wayBill.DriverName = '';
                }
            });

            $scope.$watch('tempCorrection.data', (filterVal, oldValue) => {
                if (angular.equals(filterVal, oldValue) || oldValue == null) {
                    return;
                }
                if (filterVal == "") {
                    $scope.wayBill = angular.copy($scope.wayBillBuff);
                    $scope.goodsGridOptions.grid.setDataSource(this.goodsGridDataSource($scope.wayBill.Goods));
                    if ($scope.subWaybillsGridOptions.grid) {
                        $scope.subWaybillsGridOptions.grid.setDataSource($scope.wayBill.SubWayBills.toDatasource());
                    }
                } else {
                    $scope.wayBillBuff = angular.copy($scope.wayBill);
                    rSService.getWBCorrection(filterVal).then((result) => {
                        if (result.Waybill) {
                            $scope.wayBill = result.Waybill;
                            $scope.goodsGridOptions.grid.setDataSource(this.goodsGridDataSource($scope.wayBill.Goods));
                            if ($scope.subWaybillsGridOptions.grid) {
                                $scope.subWaybillsGridOptions.grid.setDataSource($scope.wayBill.SubWayBills.toDatasource());
                            }
                        }
                        return "";
                    });
                }
            });

            $scope.saveWB = this.saveWB;
            $scope.closePopup = this.closePopup;
            $scope.editWB = this.editWB;
            $scope.createIncome = this.createIncome;
            $scope.viewDoc = this.viewDoc;
            $scope.historyWB = this.historyWB;
            $scope.historyWB();
            $scope.historyWB();

            $timeout(() => {
                $('#multiDelete').on('click', () => {
                    this.multiDelete();
                });
            });
        }

        private goodsGridColumns:any =
            [
                {
                    field: 'Name',
                    titleTranslated: 'caption',
                    width: 200,
                    locked: true
                },
                {
                    field: 'BCode',
                    titleTranslated: 'barcode',
                    width: 80
                },
                {
                    field: 'Unit',
                    titleTranslated: 'unit',
                    width: 50
                },
                {
                    field: 'SCount',
                    titleTranslated: 'Count',
                    width: 50,
                    footerTemplate: '<span translate=Count> </span> #= kendo.toString(data.SCount && data.SCount.sum  ? parseFloat(data.SCount.sum).toFixed(4)  : 0) # '
                },
                {
                    field: 'Price',
                    titleTranslated: 'ItemPrice',
                    width: 50,
                    format: Core.Kendo.Format.Price4
                },
                {
                    field: 'Amount',
                    titleTranslated: 'ammount',
                    width: 50,
                    format: Core.Kendo.Format.Price4,
                    footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.Amount && data.Amount.sum  ? data.Amount.sum.toFixed(4) : 0) # '
                },
                {
                    field: 'VatType',
                    titleTranslated: 'VatType',
                    width: 50
                }
            ];

        private goodsGridOptions(dataSource:any):Core.Interfaces.IGridOptions {
            var gridOptions = Core.Kendo.GridView.initGridOptions(this.goodsGridColumns);
            gridOptions.editable = false;
            gridOptions.scrollable = true;
            gridOptions.height = 250;
            gridOptions.filterable = {
                mode: "row"
            };          
            gridOptions.rowTemplate = '<tr ng-style="{background: #:Status# == 1 ? \'green\': #:Status# == -1 ? \'red\':  \'\'}">' +
                '<td><div style="white-space: nowrap;max-width: 200px;overflow: hidden;text-overflow:ellipsis;">#:Name #</div></td>' +
                '<td>#:BCode #</td>' +
                '<td>#:Unit #</td>' +
                '<td>#:SCount #</td>' +
                '<td>#:Price #</td>' +
                '<td>#:Amount #</td>' +
                '<td>{{::"vat_type" + #:VatType # | translate}}</td>' +
                '</tr>';
            gridOptions.dataSource = this.goodsGridDataSource(dataSource);
            return gridOptions;
        }

        private goodsGridDataSource(data:any) {
            return new kendo.data.DataSource({
                data: data,
                aggregate: [
                    {field: "SCount", aggregate: "sum"},
                    {field: "Amount", aggregate: "sum"}
                ],
                schema: {
                    model: {
                        id: "ID"
                    }
                }
            });
        }

        private subWaybillsGridColumns:any =
            [
                {field: 'ID', titleTranslated: 'ID'},
                {field: 'WayBillNum', titleTranslated: 'WaybillNum'},
            ];

        private subWaybillsGridOptions(dataSource:any):Components.IApxGrid {
            var gridOptions:Components.IApxGrid = {
                columns: this.subWaybillsGridColumns,
                dataSource: new kendo.data.DataSource({
                    data: dataSource,
                    pageSize: 100,
                    schema: {
                        model: {
                            id: "ID"
                        }
                    }
                }),
                parentScope: this.$scope,
                gridEvents: [
                    this.getAddEvent()
                ]
            }
            return gridOptions;
        }

        private getAddEvent():Components.IEvent {
            var addEventType:Components.EventType = Components.EventType.Save;
            var addEvent:Components.IEvent =
            {
                eType: addEventType,
                eFunction: this.addEventFunctionality
            };
            return addEvent;
        }

        addEventFunctionality = (e) => {

        };

        bcDropdownOptions = () => {

        };

        multiDelete = () => {
            var translate = Core.Utils.Helper.getServiceFromJs(Globals.translate);

            var selectedRows = this.$scope.subWaybillsGridOptions.grid.select();
            var length:number = selectedRows.length;
            if (length === 0)
                return;

            var r:any;
            if (length === 1) {
                r = Core.Utils.ModalForm.openConfirmation(translate.instant("deleteConfirmation"));
            } else {
                r = Core.Utils.ModalForm.openConfirmation(translate.instant("multiDeleteConfirmation") + " " + selectedRows.length);
            }
            r.result.then(() => {
                _.forEach(selectedRows, (item) => {
                    let obj = this.$scope.subWaybillsGridOptions.grid.dataItem(item);
                    //let model: Account.Controllers.ISupplieModel = ;
                    this.$scope.subWaybillsGridOptions.grid.dataSource.remove(obj);
                    for (var i = 0; i < this.$scope.wayBill.SubWayBills.length; i++) {
                        if (this.$scope.wayBill.SubWayBills[i].supplieID === obj.get("supplieID")) {
                            this.$scope.wayBill.SubWayBills.splice(i, 1);
                            break;
                        }
                    }
                    this.$scope.subWaybillsGridOptions.grid.dataSource.sync();
                });
            });
        };

        saveWB = () => {
            var translate = Core.Utils.Helper.getServiceFromJs(Globals.translate);
            this.rSService.saveWaybill({Waybill: this.$scope.wayBill}).then(result => {
                if (typeof result.Error !== "undefined" && result.Error !== 0) {
                    this.rSService.getError(result.Error);
                    return;
                }
                if (typeof result.WaybillID !== "undefined") {
                    this.toaster.pop('success', translate.instant('Info'), translate.instant('docSave'));
                    this.$uibModalInstance.close(result);
                }
                return "";
            });
        };

        editWB = () => {
        
            this.rSService.getWBChanges(this.$scope.wayBill.Docs_id).then(result => {
                if (typeof result.Error !== "undefined" && result.Error !== 0) {
                    this.rSService.getError(result.Error);
                    return "";
                } else {
                    if (result.WbChanges == null) {
                        if (this.$scope.goodsTempData != null) {
                            this.$scope.wayBill.Goods = this.$scope.goodsTempData;
                        }
                        this.$scope.goodsOldData = null;
                    } else {
                        this.$scope.goodsTempData = this.$scope.wayBill.Goods;
                        this.$scope.wayBill.Goods = result.WbChanges.Current;
                        this.$scope.goodsOldData = result.WbChanges.OldChanges;

                        this.$scope.disableSave = false;
                        this.$scope.disableEdit = true;
                    }
                }             
            });            
        };

        createIncome = () => {
            var formData:any = {};
            if (this.$scope.wayBill.Docs_id) {
                alert(this.$scope.wayBill.Docs_id);
            } else {
                kendo.ui.progress($('#waybillModalFormFull'), true);                
                this.rSService.getIncomeDocTemplate(this.$scope.wayBill.ID).then(result => {
                    formData.IncomeDocTemplate = result.Result;
                    kendo.ui.progress($('#waybillModalFormFull'), false);
                    var dlg = Core.Utils.ModalForm.openModalForm("modal.waybill.income.html", Controllers.WaybillIncome.getName(), formData, Core.Utils.ModalSize.Large,false);
                    dlg.result.then(result => {
                        if (result && result.DocId) {
                            this.$scope.wayBill.ReceivedDocsID = result.DocId;
                            Account.Utils.Documents.getDocDetails(1, Account.Interfaces.FForms.GoodInnerBuying, result.DocId);
                        }
                    });
                });
            }
        };

        viewDoc = () => {
            Account.Utils.Documents.getDocDetails(1, Account.Interfaces.FForms.GoodInnerBuying, this.$scope.wayBill.ReceivedDocsID);
        };

        historyWB = () => {
            var translate = Core.Utils.Helper.getServiceFromJs(Globals.translate);
            var filter = Core.Utils.Helper.getServiceFromJs(Globals.filter);
            this.rSService.getWBCorrections(this.$scope.wayBill.ID).then(result => {
                if (typeof result.Error !== "undefined" && result.Error !== 0) {
                    this.rSService.getError(result.Error);
                    return;
                }
                if (typeof result.WbCorrectionList !== "undefined" && result.WbCorrectionList.length !== 0) {
                    var acc = [{ID: '', templateName: translate.instant('Current')}];
                    _.each(result.WbCorrectionList, (x:any) => {
                        x.templateName = x.UserName + ' - ' + filter('date')(filter('dateFormat')(x.CorrectDate, "dd/MM/yyyy"), "dd/MM/yyyy");
                        acc.push(x);
                    });
                    this.$scope.WbCorrectionList = acc;
                }


                return "";
            });
        };

        closePopup = () => {
            this.$uibModalInstance.close();
        }
    }

}