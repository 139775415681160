/// <reference path='../_reference.ts' />
module Apex.RsService.Controllers {

    import IAuthMofUser = Apex.RsService.Interfaces.IAuthMofUser;
    import IWayBill = Apex.RsService.Interfaces.IWayBill;
    export class WaybillBooks extends Core.Controller {

        public static $inject = [
            Globals.scope, 'accountService', 'rSService', 'dialogs', '$filter', '$rootScope', Globals.translate, Globals.toaster
        ];

        constructor(private $scope: any, private accountService: Account.Services.Account, private rSService: Services.RS, private dialogs: any, private $filter: any, private $rootScope, private $translate: any, private toaster: any) {

            super(arguments, WaybillBooks.$inject);

            $scope.events = this;

            $scope.mofDictionary = {
                transportType: [],
                waybillType: [],
                waybillUnit: [],
                carNumbers: []
            };

            $scope.auth = {
                authenticated: false
            };
            this.getMofDicionary();


            $scope.waybillBookGridOptions = this.waybillBookGridOptions();

            var yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);

            $scope.filters = {
                dateBetween: {
                    fromDate: yesterday,
                    toDate: new Date()
                },
                onlyUpload: 0
            };

            var dataSourceIsLoaded = false;

            var openLookupEvent = () => {
                if (!dataSourceIsLoaded) {
                    accountService.getAccounts(13).then(a => {
                        $scope.debetAccnOptions.dataSource.data(a.Result);
                        return "";
                    });
                    dataSourceIsLoaded = true;
                }
            };


            $scope.debetAccnOptions = {
                valueText: 'AccWithName',
                columns: this.devetCreditColumns,
                dataSource: new kendo.data.DataSource({
                    data: [],

                }),
                openEvent: openLookupEvent
            };

            $scope.changeSelectedWB = function (selectedWBBook) {
                var focusedRowModel: IWayBill = selectedWBBook;
                $scope.selectedWBBook = focusedRowModel;
            };

            var timeoutCode;
            var delayInMs = 2000;

            $scope.$watch('filters', (filterVal) => {

                if (!filterVal[1])
                    return;

                if (filterVal[0]) {
                    $scope.filters = filterVal[0];
                    this.$scope.showRefreshInfo = true;
                }

            }, true);

            this.allowedOpersDS();

        }

        devetCreditColumns: Array<Core.Interfaces.IGridColumn> = [
            { field: 'Acc', titleTranslated: 'acc', width: 110 },
            { field: 'Name', titleTranslated: 'nameCaption' }
        ];

        allowedOpersDS() {
            this.accountService.getOpers().then((response) => {
                var acc = [{ OperID: '', templateName: this.$translate.instant('AllOperations') }];
                _.each(response.Result, (x: any) => {
                    x.templateName = x.OperID + ' - ' + x.Name;
                    acc.push(x);
                });
                this.$scope.allOpers = acc;
            });
        }

        refresh() {
            this.$scope.waybillBookGridOptions.gridView.showLoading();
            this.$scope.events.reloadGridData(this.$scope.filters);
        }

        sendWB(selectedWBBook) {
            if (typeof selectedWBBook === "undefined")
                return;
            var dlg = this.dialogs.confirm(this.$translate.instant('Attention'), this.$translate.instant('sendWBConfirmation'));
            dlg.result.then(btn => {
                this.rSService.sendWB(selectedWBBook.WaybillID).then(results => {
                    this.doAfterAction(results, "Sent");
                    return "";
                });
            });
        }

        closeWB(selectedWBBook) {
            if (typeof selectedWBBook === "undefined")
                return;
            var dlg = this.dialogs.confirm(this.$translate.instant('Attention'), this.$translate.instant('closeWBConfirmation'));
            dlg.result.then(btn => {
                this.rSService.closeWB(selectedWBBook.WaybillID).then(results => {
                    this.doAfterAction(results, "Closed");
                    return "";
                });
            });
        }

        uploadWB(selectedWBBook) {
            if (typeof selectedWBBook === "undefined")
                return;

            var filter = this.$scope.filters;

            var formData: any = {
                WayBill: {},
                mofDictionary: this.$scope.mofDictionary
            };

            this.rSService.createWayBillFromDoc(null, selectedWBBook.Docs_id).then((result) => {
                formData.WayBill = result.Waybill;
                var dlg = Core.Utils.ModalForm.openModalForm("modal.waybill.html", Controllers.WaybillEdit.getName(), formData, Core.Utils.ModalSize.Medium);
                dlg.result.then(result => {
                    if (typeof result !== "undefined")
                        this.reloadGridData(filter);
                });
                return "";
            });
        }



        editWB(selectedWBBook) {
            if (typeof selectedWBBook === "undefined")
                return;

            var filter = this.$scope.filters;

            var formData: any = {
                WayBill: {},
                mofDictionary: this.$scope.mofDictionary
            };

            this.rSService.getWayBill(selectedWBBook.WaybillID).then((result) => {
                if (typeof result.Waybill !== "undefined") {
                    formData.WayBill = result.Waybill;
                    var dlg = Core.Utils.ModalForm.openModalForm("modal.waybill.html", Controllers.WaybillEdit.getName(), formData, Core.Utils.ModalSize.Medium);
                    dlg.result.then(result => {
                        if (typeof result !== "undefined")
                            this.reloadGridData(filter);
                    });
                }
                return "";
            });
        }

        deleteWBLocal(selectedBook) {
            if (typeof selectedBook === "undefined")
                return;
            var dlg = this.dialogs.confirm(this.$translate.instant('Attention'), this.$translate.instant('deleteConfirmation'));
            dlg.result.then(btn => {
                this.rSService.deleteWBLocal(selectedBook.WaybillID).then(results => {
                    this.doAfterAction(results, 'LocalDeleted');
                    return "";
                });
            });
        }

        doAfterAction(results, actionName) {
            var filter = this.$scope.filters;
            if (results && results.Error === 0) {
                delete this.$scope.selectedWBBook;
                this.toaster.pop('success', this.$translate.instant('Info'), this.$translate.instant('wb' + actionName));
                this.reloadGridData(filter);
            } else if (!results.success) {
                delete this.$scope.selectedWBBook;
                this.toaster.pop('error', "Error", this.$translate.instant('wbNot' + actionName));
            }
        }


        reloadGridData(filter) {
            var data = {
                D1: new Date(filter.dateBetween.fromDate.setHours(0, 0, 0, 0)),
                D2: new Date(filter.dateBetween.toDate.setHours(23, 59, 59, 999)),
                Client: null,
                Numberin: null,
                Numberout: null,
                OperId: null,
                Cuser: null,
                Iud: null
            };

            if (filter.oper) {
                data.OperId = filter.oper;
            }

            if (filter.numberIn) {
                data.Numberin = filter.numberIn;
            }

            if (filter.numberOut) {
                data.Numberout = filter.numberOut;
            }

            if (filter.onlyUpload > 0) {
                data.Iud = filter.onlyUpload;
            }

            if (filter.debet) {
                data.Client = filter.debet.Acc;
            }


            this.rSService.getWBBooks(data).then((result: any) => {
                if (result != null && result.WbBookList != null) {
                    for (var i = 0; i < result.WbBookList.length; i++) {
                        result.WbBookList[i].OperType = this.getOperName(result.WbBookList[i].Oper_id);
                        result.WbBookList[i].Vg = result.WbBookList[i].Vg.toString();
                    } 
                    this.$scope.waybillBookGridOptions.dataSource.data(result.WbBookList); 
                }
                this.$scope.showRefreshInfo = false;
                this.$scope.waybillBookGridOptions.gridView.hideLoading();
            });
        }

        getOperName(operId) {
            for (var i = 0; i < this.$scope.allOpers.length; i++) {
                if (this.$scope.allOpers[i].OperID == operId) {
                    return this.$scope.allOpers[i].templateName;
                }
            }

        }

        private templates: Array<Core.Interfaces.IGridColumnTemplate> = [
            {
                fieldName: 'SendTime',
                template: '<div class="text-center" ng-if="dataItem.WaybillID"><span class="fa fa-check"></span></div>'
            },
            {
                fieldName: 'Blocking',
                template: '<div class="text-center" ng-if="dataItem.Blocking"><img src="./img/lock.png" width="16"></div>'
            },
            {
                fieldName: 'RecStatus',
                template: '<div class="text-center" ng-if="dataItem.RecStatus"><img src="./img/fx.png" width="16"></div>'
            },
            {
                fieldName: 'DBName',
                template: '{{dataItem.DBAcc}} - {{dataItem.DBName}}'
            },
            {
                fieldName: 'CRName',
                template: '{{dataItem.CRAcc}} - {{dataItem.CRName}}'
            }
        ];

        private waybillsGridColumns: Array<Core.Interfaces.IGridColumn> = [
            { field: 'SendTime', titleTranslated: 'uploaded', width: 32, filterable: false },
            { field: 'Blocking', titleTranslated: 'Blocking', width: 32, filterable: false, objectType: 'boolean' },
            { field: 'RecStatus', titleTranslated: 'status', width: 32, filterable: false, objectType: 'boolean' },
            { field: 'DDate', titleTranslated: 'DATE', width: 100, format: Core.Kendo.Format.Date },
            { field: 'DBName', titleTranslated: 'sender', width: 140 },
            { field: 'CRName', titleTranslated: 'receiver', width: 140 },
            { field: 'OperType', titleTranslated: 'operType', width: 140 },
            { field: 'NumberIn', titleTranslated: 'numberin', width: 80 },
            { field: 'NumberOut', titleTranslated: 'numberout', width: 80 },
            { field: 'Vg', titleTranslated: 'amount', width: 100, format: Core.Kendo.Format.Price2 },
            { field: 'WaybillNum', titleTranslated: 'WaybillNum', width: 90 },
            { field: 'CUser', titleTranslated: 'cuser', width: 60 },
            { field: 'WaybillID', titleTranslated: 'wbId', width: 100 },
        ];

        private waybillBookGridOptions(): Components.IApxGrid {
            var self = this;

            var dataSourceConfig = {
                pageSize: 50
            };

            var gridOptions: Components.IApxGrid = <Components.IApxGrid>{
                columns: this.waybillsGridColumns,
                templates: this.templates,
                gridEvents: [this.getChangeEvent()],
                sortable: true,
                selectable: "single",
                resizable: true,
                filterable: {
                    mode: "row"
                },
                settings: {
                    virtualScroll: true
                },
                parentScope: this.$scope,
                update: function (newDataSource) {
                    this.dataSource.data(newDataSource);// = newDataSource.toDatasource(dataSourceConfig)
                },
                dataSource: new kendo.data.DataSource({
                    schema: {
                        model: {
                            fields: {
                                SendTime: { type: 'date' },
                                // Blocking: { type: 'string' },
                                // RecStatus: { type: 'string' },
                                DDate: { type: 'date' },
                                DBName: { type: 'string' },
                                CRName: { type: 'string' },
                                OperType: { type: 'string' },
                                NumberIn: { type: 'number' },
                                NumberOut: { type: 'number' },
                                Vg: { type: 'number' },
                                WaybillNum: { type: 'number' },
                                CUser: { type: 'string' },
                                WaybillID: { type: 'string' },
                            }
                        }
                    }
                })
            };

            return gridOptions;
        }

        private getChangeEvent(): Components.IEvent {
            var changeEventType: Components.EventType = Components.EventType.Change;
            var addEvent: Components.IEvent =
                {
                    eType: changeEventType,
                    eFunction: this.changeEventFunctionality
                };
            return addEvent;
        }

        changeEventFunctionality = (e) => {
            Core.Utils.Helper.safeApply(this.$scope, this.$scope.changeSelectedWB(e.sender.dataItem(e.sender.select())))
        };

        getMofDicionary() {
            this.rSService.getWBMOFDicionary(1).then(result => {
                this.$scope.mofDictionary.transportType = result.Result;
                return "";
            });
            this.rSService.getWBMOFDicionary(2).then(result => {
                this.$scope.mofDictionary.waybillType = result.Result;
                return "";
            });
            this.rSService.getWBMOFDicionary(4).then(result => {
                this.$scope.mofDictionary.carNumbers = [];
                for (var i = 0; i < result.Result.length; i++) {
                    this.$scope.mofDictionary.carNumbers.push(result.Result[i].Name)
                }
                return "";
            });
            this.rSService.getWBMOFDicionary(5).then(result => {
                this.$scope.mofDictionary.errorCodes = result.Result;
                return "";
            });
        }

    }
}
