/// <reference path='../_reference.ts' />
module Apex.RsService.Controllers {

    import IIncomeProduct = Apex.RsService.Interfaces.IIncomeProduct;
    import IAuthMofUser = Apex.RsService.Interfaces.IAuthMofUser;
    export interface WaybillGoodsRefScope extends angular.IScope {
        filters: any;
        auth: any;
        wbGoodsRefGridOptions: Components.IApxGrid;
        wbStatuses: any;
        wbTypes: any;
        suppliersAccnOptions: Components.IApxLookup;
        productLookup: Components.IApxLookup;
        changeSelectedProduct: Function;
        fillProdPP: Function;
        reloadGridData: Function;
        selectedProduct: IIncomeProduct;
        selectedProductProduct: IIncomeProduct;
        products: Array<IIncomeProduct>;
        productName: string;
        progress: any;
        saveDisabled: boolean;
        save: Function;
    }

    export class WaybillGoodsRef extends Core.Controller {

        public static $inject = [
            Globals.scope, 'rSService', 'accountService', 'dialogs', '$filter', '$rootScope', Globals.translate, Globals.toaster
        ];

        constructor(private $scope: WaybillGoodsRefScope, private rSService: Services.RS, private accountService: Account.Services.Account, private dialogs: any, private $filter: any, private $rootScope, private $translate: any, private toaster: any) {

            super(arguments, WaybillGoodsRef.$inject);

            this.$scope = $scope;
            this.rSService = rSService;





            $scope.products = [];
            $scope.saveDisabled = true;

            $scope.wbGoodsRefGridOptions = this.wbGoodsRefGridOptions($scope.products);

            var yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);

            $scope.filters = {
                dateBetween: {
                    fromDate: yesterday,
                    toDate: new Date()
                },
                statuses: [0, 1, 2],
                state: 'sent'
            };

            $scope.wbStatuses = [
                { id: 0, translatedText: "{{'waybill_status0' | translate}}" },
                { id: 1, translatedText: "{{'waybill_status1' | translate}}" },
                { id: 2, translatedText: "{{'waybill_status2' | translate}}" },
                { id: -2, translatedText: "{{'waybill_status-2' | translate}}" }
            ];

            $scope.wbTypes = [];
            for (var i = 1; i < 7; i++) {
                $scope.wbTypes.push({ id: i, translatedText: "{{'WbType" + i + "' | translate}}" });
            }

            var dataSourceIsLoaded = false;

            var openLookupEvent = () => {
                if (!dataSourceIsLoaded) {
                    accountService.getSuppliers().then(a => {
                        $scope.suppliersAccnOptions.dataSource = a.Result.toDatasource();
                    });
                    dataSourceIsLoaded = true;
                }
            };


            $scope.suppliersAccnOptions = {
                valueText: 'AccWithName',
                columns: this.devetCreditColumns,
                dataSource: [].toDatasource(),
                openEvent: openLookupEvent
            };

            $scope.changeSelectedProduct = function (selectedProduct) {
                var focusedRowModel: IIncomeProduct = selectedProduct;
                $scope.selectedProduct = focusedRowModel;
                $scope.selectedProductProduct = null;
            };

            $scope.productLookup = this.bcDropdownOptions();

            var timeoutCode;
            var delayInMs = 2000;

            $scope.$watch('selectedProductProduct', (filterVal: any, oldValue) => {
                if (!$scope.selectedProduct || $scope.selectedProductProduct == -1) {
                    return;
                }
                if (filterVal) {
                    $scope.selectedProduct.ProductsNu = filterVal.ProductsNu;
                    $scope.selectedProduct.Unit = filterVal.Unit;
                    $scope.selectedProduct.BCode = filterVal.BCode;
                    $scope.selectedProduct.ProducerNu = filterVal.ProducerNu;
                    $scope.selectedProduct.ProdPPId = filterVal.ProdPPId;
                    this.updateProductPPRef($scope.selectedProduct);
                } else {
                    this.updateProductPPRef($scope.selectedProduct, true);
                }
            });

            $scope.$watch('filters', (filterVal) => {

                if (!filterVal[1] || !filterVal[0].suppliers)
                    return;

                clearTimeout(timeoutCode);  //does nothing, if timeout already done

                timeoutCode = setTimeout(() => {
                    $scope.reloadGridData(filterVal[0], rSService);
                }, delayInMs);
            }, true);

            $scope.reloadGridData = this.reloadGridData;
            $scope.fillProdPP = this.fillProdPP;
            $scope.save = this.save;
        }

        devetCreditColumns: Array<Core.Interfaces.IGridColumn> = [
            { field: 'Acc', titleTranslated: 'acc', width: 110 },
            { field: 'Name', titleTranslated: 'nameCaption' },
            { field: 'SN', titleTranslated: 'SN' }
        ];

        bcDropdownOptions = () => {
            var pProductsLookup: Components.IApxLookup =
                {
                    columns: [
                        { field: 'BCode', width: 120, titleTranslated: 'barcode' },
                        { field: 'ProductsNu', width: 220, titleTranslated: 'caption' },
                        { field: 'Unit', width: 70, titleTranslated: 'unit' },
                        { field: 'ProducerNu', width: 220, titleTranslated: 'producer' }
                    ],
                    dataSource: Core.Kendo.GridView.initAutoQueryDataSource(this.accountService.productsQueryUrl),
                    valueText: 'ProductsNu'
                };

            return pProductsLookup;
        }

        reloadGridData = (filter) => {
            var data = {
                startDate: new Date(filter.dateBetween.fromDate.setHours(0, 0, 0, 0)),
                endDate: new Date(filter.dateBetween.toDate.setHours(23, 59, 59, 999)),
                types: null,
                sn: filter.suppliers.SN
            };


            if (filter.operations) {
                data.types = filter.operations;
            }

            this.rSService.getBuyerIncomeProducts(data).then((result) => {
                this.$scope.products = result.Result;
                this.$scope.wbGoodsRefGridOptions = this.wbGoodsRefGridOptions(result.Result);
            });
        }

        private templates: Array<Core.Interfaces.IGridColumnTemplate> = [
            {
                fieldName: 'Product',
                template: '<span>{{:: dataItem.ProductsNu }}</span>' +
                '<span ng-if="dataItem.updating" class="k-icon k-loading"></span>',
                editor(container, options) {
                    $('<apx-lookup style="width:600px" ng-model="dataItem.' + options.field + '" model="options.parentScope.selectedProductProduct" apx-placeholder="options.parentScope.selectedProduct.BCode != null ? \'ClearProduct\' : \'ChooseProduct\'" options="options.parentScope.productLookup" apx-required="options.parentScope.selectedProduct.BCode == null"></apx-lookup>')
                        .appendTo(container);
                }
            }
        ];

        private wbGoodsRefGridColumns: Array<Core.Interfaces.IGridColumn> = [
            {
                field: 'WbProductCode',
                titleTranslated: 'code',
                width: 80
            },
            {
                field: 'WbProductName',
                titleTranslated: 'caption',
                width: 120
            },
            {
                field: 'WbProductUnit',
                titleTranslated: 'unit',
                width: 50
            },
            {
                field: 'BCode',
                titleTranslated: 'barcode',
                width: 80,
                cssClass: 'border-left'
            },
            {
                field: 'Product',
                editable: true,
                titleTranslated: 'caption',
                width: 120
            },
            {
                field: 'Unit',
                titleTranslated: 'unit',
                width: 50
            },
            {
                field: 'ProducerNu',
                titleTranslated: 'producer',
                width: 150
            }
        ];

        private wbGoodsRefGridOptions(dataSource: Array<IIncomeProduct>): Components.IApxGrid {

            var dataSourceConfig = {
                pageSize: 50,
                schema: {
                    model: {
                        id: "WbProductCode",
                        fields: {
                            WbProductCode: { editable: false, type: 'string' },
                            WbProductName: { editable: false, type: 'string' },
                            WbProductUnit: { editable: false, type: 'string' },
                            BCode: { editable: false, type: 'string' },
                            Unit: { editable: false, type: 'string' },
                            ProducerNu: { editable: false, type: 'string' }
                        }
                    }
                }
            };

            var gridOptions: Components.IApxGrid = <Components.IApxGrid>{
                columns: this.wbGoodsRefGridColumns,
                templates: this.templates,
                dataSource: new kendo.data.DataSource(dataSourceConfig),
                gridEvents: [this.getChangeEvent()],
                filterable: {
                    mode: "row"
                },
                settings: {
                    virtualScroll: true,
                    editble: {
                        disableEditRow: false
                    },
                    navigatable: true
                },
                parentScope: this.$scope,
                update(newDataSource) {
                    this.dataSource.data(newDataSource);// newDataSource.toDatasource(dataSourceConfig);
                }
            };

            return gridOptions;
        }

        private getChangeEvent(): Components.IEvent {
            var changeEventType: Components.EventType = Components.EventType.Change;
            var addEvent: Components.IEvent =
                {
                    eType: changeEventType,
                    eFunction: this.changeEventFunctionality
                };
            return addEvent;
        }

        changeEventFunctionality = (e) => {
            Core.Utils.Helper.safeApply(this.$scope, this.$scope.changeSelectedProduct(e.sender.dataItem(e.sender.select())));
        };

        updateProductPPRef = (selectedProduct, deleteRef?: boolean) => {
            var translate = Core.Utils.Helper.getServiceFromJs(Globals.translate);
            this.rSService.updateProductPPRef(selectedProduct, this.$scope.filters.suppliers.SN, deleteRef).then(result => {
                if (typeof result.Error !== "undefined" && result.Error !== 0) {
                    this.rSService.getError(result.Error);
                    return;
                }
                this.$scope.wbGoodsRefGridOptions.grid.dataSource.read();
                this.toaster.pop('success', translate.instant('Info'), translate.instant('successUpdateProductPPRefs'));
                this.reloadGridData(this.$scope.filters);
            });
        };

        fillProdPP = (i) => {
            i++;
            var products = this.$scope.products;
            if (products.length - 1 < i) {
                this.$scope.progress = 0;
                this.$scope.wbGoodsRefGridOptions.grid.dataSource.read();
                return;
            }

            var product = products[i];
            this.$scope.productName = product.WbProductName;
            this.$scope.progress = i;
            if (!product.ProdPPId) {
                this.rSService.fillProdPP(product).then(result => {
                    if (result.Product) {
                        product = result.Product;
                        this.$scope.saveDisabled = false;
                    }
                    this.fillProdPP(i);
                });
            } else {
                this.fillProdPP(i);
            }

        };

        save = () => {
            //TODO
            this.$scope.saveDisabled = true;
        };
    }
}
