/// <reference path="./../_reference.ts"/>
module Apex.RsService.Interfaces {
    "use strict";
    export interface IInvoice {
        InvoiceID?:number;
        NumberOut?:string;
        FSeries?:string;
        FNumber?:string;
        OperationDate?:Date;
        RegistrationDate?:Date;
        RegDate?:Date;
        SellerID?:number;
        BuyerID?:number;
        SellerSN?:string;
        BuyerSN?:string;
        SellerName?:string;
        BuyerName?:string;
        SellerUnID?:number;
        BuyerUnID?:number;
        OverheadNo?:string;
        OverheadDate?:Date;
        Status?:number;
        SeqNumS?:string;
        SeqNumB?:string;
        UserID?:number;
        K_ID?:number;
        R_UN_ID?:number;
        K_TYPE?:number;
        B_S_UserID?:number;
        DecStatus?:number;
        NoText?:string;
        OrgName?:string;
        OrgSn?:string;
        Notes?:string;
        WAS_REF?:number;
        Amount?:number;
        Details?:any;
        NosList?:any;
    }
} 