/// <reference path='../../_reference.ts' />
module Apex.RsService.Controllers {


    import IInvoice = Apex.RsService.Interfaces.IInvoice;
    import IMOFDictionary = Apex.RsService.Interfaces.IMOFDictionary;
    export class InvoiceEditData {
        invoice: IInvoice;
        correcting: boolean;
    }
    export interface InvoiceEditScope extends angular.IScope {
        invoice: IInvoice;
        modalInstance: any;
        closePopup: Function;
        goodsGridOptions: Components.IApxGrid;
        waybillsGridOptions: Components.IApxGrid;
        isDisabled: boolean;
        viewWB: Function;
        saveDets: Function;
        generationByReturnWB: Function;
        mofDictionary: any;
        kInvoice: IInvoice;
        originalInvoice: IInvoice;
        correct: any;
        correcting: boolean;
        returnedWaybills: any;
        chosenReturnedWaybills: any;
    }

    export class InvoiceEdit extends Core.Controller {
        public static $inject = [
            Globals.scope, 'data', 'rSService', Globals.modalInstance, Globals.translate, Globals.toaster, Globals.http
        ];

        $scope:InvoiceEditScope;
        rSService:Services.RS;
        $uibModalInstance:any;
        toaster:any;

        constructor($scope: InvoiceEditScope, data: InvoiceEditData, rSService:Services.RS, $uibModalInstance, $translate:any, toaster, private $http:angular.IHttpService) {
            super(arguments, InvoiceEdit.$inject);

            this.$scope = $scope;
            this.rSService = rSService;
            this.$uibModalInstance = $uibModalInstance;
            this.toaster = toaster;

            $scope.mofDictionary = {
                transportType: [],
                waybillType: [],
                waybillUnit: [],
                carNumbers: []
            };

            $scope.invoice = data.invoice;
            $scope.correcting = data.correcting;

            $scope.correct = {};

            this.getMofDicionary();

            if ($scope.invoice.K_ID < 0) {
                $scope.correct.state = 'original';
                $scope.originalInvoice = angular.copy($scope.invoice);
                rSService.getKInvoiceView($scope.invoice.InvoiceID).then((result) => {
                    $scope.kInvoice = result.Invoice;
                });
            } else if ($scope.invoice.K_ID > 0) {
                $scope.correct.state = 'corrected';
                $scope.kInvoice = angular.copy($scope.invoice);
                rSService.getInvoiceView($scope.invoice.K_ID).then((result) => {
                    $scope.originalInvoice = result.Invoice;
                });
            }

            if ($scope.correcting) {
                var filter = Core.Utils.Helper.getServiceFromJs(Globals.filter);
                $scope.returnedWaybills = [];
                rSService.getReturnedWaybills($scope.invoice.InvoiceID).then((result) => {
                    _.each(result.Result, (x:any) => {
                        x.templateName = x.WaybillNum + ' - ' + filter('date')(filter('dateFormat')(x.WBDate, "dd/MM/yyyy"), "dd/MM/yyyy");
                        $scope.returnedWaybills.push(x);
                    });
                });
            }


            $scope.goodsGridOptions = this.goodsGridOptions($scope.invoice.Details);
            $scope.waybillsGridOptions = this.waybillsGridOptions($scope.invoice.NosList);

            $scope.$watch('correct.state', (filterVal, oldValue) => {
                if (angular.equals(filterVal, oldValue)) {
                    return;
                }

                if (filterVal === 'original') {
                    $scope.invoice = angular.copy($scope.originalInvoice);
                } else if (filterVal === 'corrected') {
                    $scope.invoice = angular.copy($scope.kInvoice);
                }
                $scope.goodsGridOptions.grid.setDataSource($scope.invoice.Details.toDatasource());
                $scope.waybillsGridOptions.grid.setDataSource($scope.invoice.NosList.toDatasource());
            });

            $scope.isDisabled = true;

            $scope.closePopup = this.closePopup;

            $scope.viewWB = this.viewWB;
            $scope.saveDets = this.saveDets;
            $scope.generationByReturnWB = this.generationByReturnWB;
        }

        private goodsGridColumns:any =
            [
                {
                    field: 'Good',
                    titleTranslated: 'caption',
                    width: 200,
                    locked: true
                },
                {
                    field: 'Unit',
                    titleTranslated: 'unit',
                    width: 50
                },
                {
                    field: 'SCount',
                    titleTranslated: 'Count',
                    width: 50,
                    footerTemplate: '<span translate=Count> </span> #= kendo.toString(data.Count && data.Count.count  ? data.Count.count : 0) # '
                },
                {
                    field: 'VatAmount',
                    titleTranslated: 'vat',
                    width: 50,
                    format: Core.Kendo.Format.Price4
                },
                {
                    field: 'FullAmount',
                    titleTranslated: 'ammount',
                    width: 50,
                    format: Core.Kendo.Format.Price4,
                    footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.Amount && data.Amount.sum  ? data.Amount.sum.toFixed(4) : 0) # '
                }
            ];

        private goodsGridOptions(dataSource:any):Components.IApxGrid {
            var gridOptions:Components.IApxGrid = {
                columns: this.goodsGridColumns,
                dataSource: new kendo.data.DataSource({
                    data: dataSource,
                    aggregate: [
                        {field: "SCount", aggregate: "count"},
                        {field: "FullAmount", aggregate: "sum"}
                    ],
                    schema: {
                        model: {
                            id: "ID"
                        }
                    }
                }),
                settings: {
                    selectable: "multiple",
                    editble: {
                        disableEditRow: !this.$scope.correcting
                    }
                },
                parentScope: this.$scope,
                gridEvents: [
                    this.getEditEvent()
                ]
            };
            return gridOptions;
        }

        getEditEvent():Components.IEvent {
            var editEventType:Components.EventType = Components.EventType.Edit;
            var editEvent:Components.IEvent =
            {
                eType: editEventType,
                eFunction: this.editEventFunctionality
            };
            return editEvent;
        }

        editEventFunctionality = (e:any) => {

        };

        private waybillsGridTemplate:Array<Core.Interfaces.IGridColumnTemplate> = [
            {
                fieldName: 'NosID',
                template: '<a class="k-button k-button-icontext k-grid-edit fa-icon-eye" ng-click="options.parentScope.viewWB(dataItem.OverheadNo)">' +
                '<span class="k-icon k-edit"></span>' +
                '</a>'
            }
        ];


        private waybillsGridColumns:any =
            [
                {
                    field: 'OverheadNo',
                    titleTranslated: 'WaybillNum',
                    width: 100
                },
                {
                    field: 'OverheadDate',
                    titleTranslated: 'beginDate',
                    width: 100,
                    format: Core.Kendo.Format.Date
                },
                {field: 'NosID', title: "&nbsp;", width: 20}
            ];

        private waybillsGridOptions(dataSource:any):Components.IApxGrid {
            var gridOptions:Components.IApxGrid = {
                columns: this.waybillsGridColumns,
                templates: this.waybillsGridTemplate,
                dataSource: new kendo.data.DataSource({
                    data: dataSource
                }),
                parentScope: this.$scope
            };
            return gridOptions;
        }

        closePopup = () => {
            if (this.$scope.correcting) {
                this.rSService.deleteInvoice(this.$scope.kInvoice.InvoiceID).then((result) => {
                    this.$uibModalInstance.close();
                });
            } else {
                this.$uibModalInstance.close();
            }
        };

        viewWB = (wbNum) => {
            var formData:any = {
                WayBill: {},
                mofDictionary: this.$scope.mofDictionary
            };

            this.rSService.getWayBillByNumber(wbNum).then((result) => {
                formData.WayBill = result.Waybill;
                var dlg = Core.Utils.ModalForm.openModalForm("modal.waybill.html", Controllers.WaybillEdit.getName(), formData, Core.Utils.ModalSize.Medium);
                dlg.result.then(result => {
                });
            });
        };

        saveDets = (invoice:IInvoice) => {
            invoice.Details = this.$scope.goodsGridOptions.grid.dataSource.data();
            invoice.NosList = this.$scope.waybillsGridOptions.grid.dataSource.data();
            var translate = Core.Utils.Helper.getServiceFromJs(Globals.translate);
            this.rSService.saveInvoiceDets(invoice.InvoiceID, invoice.Details, invoice.NosList).then(result => {
                if (typeof result.Error !== "undefined") {
                    if (result.Error === 0) {
                        this.toaster.pop('success', translate.instant('Info'), translate.instant('docSave'));
                        this.$uibModalInstance.close(result);
                    } else if (result.Error !== 204) {
                        this.closePopup();
                    } else {
                        this.rSService.getError(result.Error);
                    }
                }
            });
        };

        generationByReturnWB = () => {
            var bookIds = [];
            for (var i = 0; i < this.$scope.chosenReturnedWaybills.length; i++) {
                bookIds.push(this.$scope.chosenReturnedWaybills[i].BookID);
            }

            this.rSService.mergeGoodsByReturnedWBResponse(this.$scope.goodsGridOptions.grid.dataSource.data(), bookIds).then(result => {
                this.$scope.invoice.Details = result.Result;
                this.$scope.goodsGridOptions.grid.dataSource.read();
                for (var i = 0; i < this.$scope.chosenReturnedWaybills.length; i++) {
                    this.$scope.invoice.NosList.push(
                        {
                            OverheadNo:this.$scope.chosenReturnedWaybills[i].WaybillNum,
                            OverheadDate:this.$scope.chosenReturnedWaybills[i].WBDate
                        }
                    );
                }
                this.$scope.waybillsGridOptions.grid.dataSource.read();
                this.$scope.chosenReturnedWaybills = [];
            });
        };

        getMofDicionary() {
            this.rSService.getWBMOFDicionary(1).then(result => {
                this.$scope.mofDictionary.transportType = result.Result;
            });
            this.rSService.getWBMOFDicionary(2).then(result => {
                this.$scope.mofDictionary.waybillType = result.Result;
            });
            this.rSService.getWBMOFDicionary(4).then(result => {
                this.$scope.mofDictionary.carNumbers = [];
                for (var i = 0; i < result.Result.length; i++) {
                    this.$scope.mofDictionary.carNumbers.push(result.Result[i].Name)
                }
            });
        }
    }

}