/// <reference path='../_reference.ts' />
module Apex.RsService.Controllers {
    import IWayBill = Apex.RsService.Interfaces.IWayBill;
    import IAuthMofUser = Apex.RsService.Interfaces.IAuthMofUser;

    export interface IWayBills {
        Waybills?: Array<IWayBill>;
    }

    export class Waybills extends Core.Controller {

        public static $inject = [
            Globals.scope, 'rSService', 'dialogs', '$filter', '$rootScope', Globals.translate, Globals.toaster
        ];

        constructor(private $scope: any, private rSService: Services.RS, private dialogs: any, private $filter: any, private $rootScope, private $translate: any, private toaster: any) {

            super(arguments, Waybills.$inject);

            $scope.events = this;

            $scope.mofDictionary = {
                transportType: [],
                waybillType: [],
                waybillUnit: [],
                carNumbers: []
            };


            $scope.auth = {
                authenticated: false
            };

            this.getMofDicionary();

            $scope.waybillsGridOptions = this.waybillsGridOptions();

            $scope.status = {
                open: false
            };

            var yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);

            $scope.filters = {
                dateBetween: {
                    fromDate: yesterday,
                    toDate: new Date()
                },
                statuses: [0, 1, 2],
                state: 'all'
            };


            $scope.changeSelectedWB = function (selectedWB) {
                var focusedRowModel: IWayBill = selectedWB;
                $scope.selectedWB = focusedRowModel;
            };

            this.initSelects($scope);

            var timeoutCode;
            var delayInMs = 2000;

            $scope.$watch('filters.state', (filterVal) => {
                var grid = $scope.waybillsGridOptions.grid;
                if (grid) {
                    if (filterVal === 'all') {
                        grid.showColumn("BuyerSN");
                        grid.showColumn("BuyerName");
                        grid.hideColumn("SellerSN");
                        grid.hideColumn("SellerName");
                        grid.hideColumn("IsConfirmed");
                        grid.hideColumn("IsCorrected");
                    } else if (filterVal === 'onlyReceived') {
                        grid.hideColumn("BuyerSN");
                        grid.hideColumn("BuyerName");
                        grid.showColumn("SellerSN");
                        grid.showColumn("SellerName");
                        grid.showColumn("IsConfirmed");
                        grid.showColumn("IsCorrected");
                    }
                }
            }, true);

            $scope.$watch('filters', (filterVal) => {
                if (!filterVal[1])
                    return;
                if (filterVal[0]) {
                    $scope.filters = filterVal[0];
                    this.$scope.showRefreshInfo = true;
                }
            }, true);

        }

        refresh() {
            this.$scope.events.reloadGridData(this.$scope.filters);
        }

        sendWB(selectedWB) {
            if (typeof selectedWB === "undefined")
                return;
            var dlg = this.dialogs.confirm(this.$translate.instant('Attention'), this.$translate.instant('sendWBConfirmation'));
            dlg.result.then(btn => {
                this.rSService.sendWB(selectedWB.ID).then(results => {
                    this.doAfterAction(results, "Sent");
                    return "";
                });
            });
        }

        closeWB(selectedWB) {
            if (typeof selectedWB === "undefined")
                return;
            var dlg = this.dialogs.confirm(this.$translate.instant('Attention'), this.$translate.instant('closeWBConfirmation'));
            dlg.result.then(btn => {
                this.rSService.closeWB(selectedWB.ID).then(results => {
                    this.doAfterAction(results, "Closed");
                    return "";
                });

            });
        }

        refuseWB(selectedWB) {
            if (typeof selectedWB === "undefined")
                return;
            var dlg = this.dialogs.confirm(this.$translate.instant('Attention'), this.$translate.instant('refuseWBConfirmation'));
            dlg.result.then(btn => {
                this.rSService.refuseWB(selectedWB.ID).then(results => {
                    this.doAfterAction(results, "Refused");
                    return "";
                });
            });
        }

        confirmWB(selectedWB) {
            if (typeof selectedWB === "undefined")
                return;
            var dlg = this.dialogs.confirm(this.$translate.instant('Attention'), this.$translate.instant('wbConfirmConfirmation'));
            dlg.result.then(btn => {
                this.rSService.confirmWB(selectedWB.ID).then(results => {
                    this.doAfterAction(results, "Confirmed");
                    return "";
                });
            });
        }

        deleteWB(selectedWB) {
            if (typeof selectedWB === "undefined")
                return;
            var dlg = this.dialogs.confirm(this.$translate.instant('Attention'), this.$translate.instant('deleteConfirmation'));
            dlg.result.then(btn => {
                this.rSService.deleteWB(selectedWB.ID).then(results => {
                    this.doAfterAction(results, 'Deleted');
                    return "";
                });
            });
        }

        editWB(selectedWB) {
            if (typeof selectedWB === "undefined")
                return;

            kendo.ui.progress($(document.body), true);

            var filter = this.$scope.filters;

            var formData: any = {
                WayBill: {},
                mofDictionary: this.$scope.mofDictionary,
                state: filter.state
            };

            this.rSService.getWayBill(selectedWB.ID).then((result) => {
                formData.WayBill = result.Waybill;
                kendo.ui.progress($(document.body), false);
                var dlg = Core.Utils.ModalForm.openModalForm("modal.waybill.html", Controllers.WaybillEdit.getName(), formData, Core.Utils.ModalSize.Medium);
                dlg.result.then(result => {
                    if (!result)
                        this.reloadGridData(filter);
                });
            });
        }

        doAfterAction(results, actionName) {
            var filter = this.$scope.filters;
            if (results && results.Error === 0) {
                delete this.$scope.selectedWB;
                this.toaster.pop('success', this.$translate.instant('Info'), this.$translate.instant('wb' + actionName));
                this.reloadGridData(filter);
            } else if (!results.success) {
                delete this.$scope.selectedWB;
                this.toaster.pop('error', "Error", this.$translate.instant('wbNot' + actionName));
            }
        }

        async reloadGridData(filter): Promise<void> {
            var data = {
                startDate: new Date(filter.dateBetween.fromDate.setHours(0, 0, 0, 0)),
                endDate: new Date(filter.dateBetween.toDate.setHours(23, 59, 59, 999)),
                types: null,
                statuses: null,
                id: null,
                buyer: null
            };

            if (filter.operations && filter.operations.length > 0) {
                data.types = filter.operations.toString();
            }

            if (filter.statuses && filter.statuses.length > 0) {
                data.statuses = ',' + filter.statuses.toString() + ',';
            }

            if (filter.id) {
                data.id = filter.id;
            }
            this.$scope.waybillsGridOptions.gridView.showLoading();
            try {
                let result = await this.rSService.getWayBills(data, filter.state);
                if (result != null) {
                    if (result.Error !== 0) {
                        this.toaster.pop('error', "Error", result.ErrorMessage);
                    } else if (result.Waybills != null) {
                        this.$scope.waybillsGridOptions.dataSource.data(result.Waybills);
                    }
                }
            }
            catch (e) {
                this.$scope.showRefreshInfo = false;
                this.$scope.waybillsGridOptions.gridView.hideLoading();
            } finally {
                this.$scope.showRefreshInfo = false;
                this.$scope.waybillsGridOptions.gridView.hideLoading();
            }

           
        }

        initSelects($scope) {
            $scope.wbStatuses = [
                { id: 0, translatedText: "{{'waybill_status0' | translate}}" },
                { id: 1, translatedText: "{{'waybill_status1' | translate}}" },
                { id: 2, translatedText: "{{'waybill_status2' | translate}}" },
                { id: -2, translatedText: "{{'waybill_status-2' | translate}}" }
            ];

            $scope.wbTypes = [];
            for (var i = 1; i < 7; i++) {
                $scope.wbTypes.push({ id: i, translatedText: "{{'WbType" + i + "' | translate}}" });
            }
        }

        templates: Array<Core.Interfaces.IGridColumnTemplate> = [
            {
                fieldName: 'IsConfirmed',
                template: '<div class="text-center" ng-if="dataItem.IsConfirmed"><span class="fa fa-check"></span></div>'
            },
            {
                fieldName: 'WbType'
                , groupHeaderTemplate: '{{"WbType" | translate}}: {{"WbType" + #=value# | translate}} {{"groupCount" | translate : "{count : #=count#}"}}'
            },
            {
                fieldName: 'Status', template: '<span>{{::"waybill_status" + dataItem.Status | translate}}</span>'
            },
            {
                fieldName: 'IsCorrected',
                template: '<div class="text-center" ng-if="dataItem.IsCorrected"><span class="fa fa-check"></span></div>'
            }
        ];

        waybillsGridColumns: Array<Core.Interfaces.IGridColumn> = [
            { field: 'WbType' },
            { field: 'IsConfirmed', titleTranslated: 'isConfirmed', width: 50, hidden: true },
            {
                field: 'Num', titleTranslated: 'WaybillNum', width: 120
                , footerTemplate: '<span translate=Count> </span> #= kendo.toString(data.Num ? data.Num.count : 0) #'
            },

            { field: 'BeginDate', titleTranslated: 'beginDate', width: 90, format: Core.Kendo.Format.Date },
            { field: 'BuyerSN', titleTranslated: 'SN', width: 90 },
            { field: 'BuyerName', titleTranslated: 'buyerName', width: 200 },
            { field: 'SellerSN', titleTranslated: 'SN', width: 100, hidden: true },
            { field: 'SellerName', titleTranslated: 'sellerName', width: 200, hidden: true },
            { field: 'ActivateDate', titleTranslated: 'activateDate', width: 90, format: Core.Kendo.Format.Date },
            { field: 'DeliveryDate', titleTranslated: 'deliveryDate', width: 90, format: Core.Kendo.Format.Date },
            {
                field: 'FullAmount', titleTranslated: 'fullAmount', width: 90, format: Core.Kendo.Format.Price2
                , footerTemplate: '<div  style="float:right"><span translate=sum> </span> #= kendo.toString(data && data.FullAmount && data.FullAmount.sum ? parseFloat(data.FullAmount.sum).toFixed(2) : 0) #</div>'
            },
            { field: 'Status', titleTranslated: 'status', width: 120 },
            { field: 'ID', titleTranslated: 'wbId', width: 100 },
            { field: 'CarNumber', titleTranslated: 'carNumber', width: 90 },
            { field: 'StartAddress', titleTranslated: 'startAddress', width: 260 },
            { field: 'EndAddress', titleTranslated: 'endAddress', width: 260 },
            { field: 'IsCorrected', titleTranslated: 'IsCorrected', width: 50, filterable: false, },
        ];
        waybillsGridOptions = (): Components.IApxGrid => {
            var self = this;
            var gridOptions: Components.IApxGrid;
            gridOptions = {
                settings: {
                    pageable: true,
                    groupable: true
                },
                columns: this.waybillsGridColumns,
                templates: this.templates,
                gridEvents: [this.getChangeEvent()],
                parentScope: this.$scope,
                dataSource: new kendo.data.DataSource({
                    data: [],
                    schema: {
                        model: {
                            fields: {
                                // WbType: { type: 'string' },
                                'IsConfirmed': { type: 'boolean' },
                                'Num': { type: 'number' },
                                'BeginDate': { type: 'date' },
                                'BuyerSN': { type: 'string' },
                                'BuyerName': { type: 'string' },
                                'SellerSN': { type: 'string' },
                                'SellerName': { type: 'string' },
                                'ActivateDate': { type: 'date' },
                                'DeliveryDate': { type: 'date' },
                                'FullAmount': { type: 'number' },
                                // Status: { type: 'string' },
                                'ID': { type: 'string' },
                                'CarNumber': { type: 'string' },
                                'StartAddress': { type: 'string' },
                                'EndAddress': { type: 'string' },
                                'IsCorrected': { type: 'boolean' },
                            }
                        }
                    },
                    group: [
                        {
                            field: 'WbType', aggregates: [{ field: "WbType", aggregate: "count" }]
                        }
                    ],
                    pageSize: 50
                })
            };

            return gridOptions;
        }

        getChangeEvent(): Components.IEvent {
            var changeEventType: Components.EventType = Components.EventType.Change;
            var addEvent: Components.IEvent =
                {
                    eType: changeEventType,
                    eFunction: this.changeEventFunctionality
                };
            return addEvent;
        }

        changeEventFunctionality = (e) => {
            Core.Utils.Helper.safeApply(this.$scope, this.$scope.changeSelectedWB(e.sender.dataItem(e.sender.select())))
        };

        async getMofDicionary(): Promise<void> {
            try {
                let resp1 = await this.rSService.getWBMOFDicionary(1);
                this.$scope.mofDictionary.transportType = resp1.Result;

                let resp2 = await this.rSService.getWBMOFDicionary(2);
                this.$scope.mofDictionary.waybillType = resp2.Result;

                let resp3 = await this.rSService.getWBMOFDicionary(4);
                this.$scope.mofDictionary.carNumbers = [];
                if (resp3.Result != null) {
                    for (var i = 0; i < resp3.Result.length; i++) {
                        this.$scope.mofDictionary.carNumbers.push(resp3.Result[i].Name)
                    }
                }
            } catch (e) {
                console.log(e);
            }
        }


    }
}
