/// <reference path='./../_reference.ts' />
module Apex.RsService.Services {
    'use strict';
    export class RS extends Core.Service {

        //authMofUser(auth):angular.IPromise<any> {
        //    return this.httpPost('/RS/CheckMofUser', auth);
        //}

        getNameFromSN(sn):angular.IPromise<any> {
            return this.httpGet('/RS/GetNameFromSN/' + sn);
        }

        getPayerTypeFromSN(sn):angular.IPromise<any> {
            return this.httpGet('/RS/GetPayerTypeFromSN/' + sn);
        }

        getPersonByIdentNo(IdentNo):angular.IPromise<any> {
            return this.httpGet('/RS/GetPersonByIdentNo/' + IdentNo);
        }

        getWBChanges(docsId):angular.IPromise<any> {
            return this.httpGet('/RS/Waybills/' + docsId + '/Changes');
        }

        getWBCorrections(waybillID):angular.IPromise<any> {
            return this.httpGet('/RS/Waybills/' + waybillID + '/Corrections');
        }

        getWBCorrection(correctionID):angular.IPromise<any> {
            return this.httpGet('/RS/Waybills/' + correctionID + '/Correction');
        }

        saveWaybill(wayBill):angular.IPromise<any> {
            return this.httpPost('/RS/Waybills/', wayBill);
        }

        createWayBillFromDoc(waybillTypeID, docID):angular.IPromise<any> {
            return this.httpPost('/RS/Waybills/CreateWayBillFromDoc', {WaybillTypeID: waybillTypeID, DocID: docID});
        }

        getWayBill(id):angular.IPromise<any> {
            return this.httpGet('/RS/Waybills/' + id);
        }

        getWayBillByNumber(num):angular.IPromise<any> {
            return this.httpGet('/RS/Waybills/ByNumber/'+num);
        }

        getIncomeDocTemplate(WaybillID):angular.IPromise<any> {
            return this.httpGet('/RS/Waybills/' + WaybillID + '/IncomeDocTemplate');
        }

        GetProductParams(prodPPId, priceType, db, cr):angular.IPromise<any> {
            var params = {
                nm: 'ProdPPId={0}&PriceType={1}&DB={2}&CR={3}',
                pr: [prodPPId, priceType, db, cr]
            };
            return this.httpGet('/RS/Waybills/GetProductParams', params, false);
        }

        getWBBooks(data:{D1:any; D2:any; Client:string; Numberin:string; Numberout:string; OperId:string; Cuser:string; Iud:string}) {
            var params = {
                nm: 'D1={0}&D2={1}',
                pr: [data.D1, data.D2]
            };
            if (data.Client) {
                params.nm += '&Client={' + (params.pr.length) + '}';
                params.pr.push(data.Client);
            }
            if (data.Numberin) {
                params.nm += '&Numberin={' + (params.pr.length) + '}';
                params.pr.push(data.Numberin);
            }
            if (data.Numberout) {
                params.nm += '&Numberout={' + (params.pr.length) + '}';
                params.pr.push(data.Numberout);
            }
            if (data.Iud !== undefined) {
                params.nm += '&Iud={' + (params.pr.length) + '}';
                params.pr.push(data.Iud);
            }
            if (data.OperId) {
                params.nm += '&OperId={' + (params.pr.length) + '}';
                params.pr.push(data.OperId);
            }
            return this.httpGet('/RS/Waybills/Books', params);
        }

        getWayBills(data:{startDate:any; endDate:any; types:string; statuses:string; id:string}, state):angular.IPromise<any> {
            var params = {
                nm: 'create_s={0}&create_e={1}',
                pr: [data.startDate, data.endDate]
            };
            if (data.types) {
                params.nm += '&Types={' + (params.pr.length) + '}';
                params.pr.push(data.types);
            }
            if (data.statuses) {
                params.nm += '&Statuses={' + (params.pr.length) + '}';
                params.pr.push(data.statuses);
            }
            if (data.id) {
                params.nm += '&Wbnumber={' + (params.pr.length) + '}';
                params.pr.push(data.id);
            }
            if (state === 'onlyReceived') {
                return this.httpGet('/RS/Waybills/ReceivedWaybills', params);
            } 
            return this.httpGet('/RS/Waybills', params);
        }

        deleteWB(ID):angular.IPromise<any> {
            return this.httpDelete('/RS/Waybills/' + ID);
        }

        deleteWBLocal(ID):angular.IPromise<any> {
            return this.httpDelete('/RS/Waybills/' + ID + '/Local/');
        }

        sendWB(ID):angular.IPromise<any> {
            return this.httpPatch('/RS/Waybills/Send', {WaybillID: ID});
        }

        closeWB(ID):angular.IPromise<any> {
            return this.httpPatch('/RS/Waybills/Close', {WaybillID: ID});
        }

        refuseWB(ID):angular.IPromise<any> {
            return this.httpPatch('/RS/Waybills/Refuse', {WaybillID: ID});
        }

        confirmWB(ID):angular.IPromise<any> {
            return this.httpPatch('/RS/Waybills/Confirm', {WaybillID: ID});
        }

        getWBMOFDicionary(CID):angular.IPromise<any> {
            return this.httpGet('/RS/Waybills/MOFDicionary/' + CID);
        }

        updateProductPPRefs(products, supplierID, supplierSN):angular.IPromise<any> {
            return this.httpPost('/RS/Waybills/UpdateProductPPRefs',
                {
                    Products:products,
                    SupplierID:supplierID,
                    SupplierSN:supplierSN
                });
        }

        updateProductPPRef(product, supplierSN, deleteRef?:boolean):angular.IPromise<any> {
            return this.httpPost('/RS/Waybills/UpdateProductPPRef',
                {
                    Product:product,
                    SupplierSN:supplierSN,
                    Delete:deleteRef
                });
        }

        fillProdPP(product):angular.IPromise<any> {
            return this.httpPost('/RS/Waybills/FillProdPP', {Product:product});
        }

        getBuyerIncomeProducts(data:{startDate:any; endDate:any; types:string; sn:string;}):angular.IPromise<any> {
            var params = {
                nm: 'Begin_s={0}&begin_e={1}',
                pr: [data.startDate, data.endDate]
            };
            if (data.types) {
                params.nm += '&Types={' + (params.pr.length) + '}';
                params.pr.push(data.types);
            }
            if (data.sn) {
                params.nm += '&Buyersn={' + (params.pr.length) + '}';
                params.pr.push(data.sn);
            }

            return this.httpGet('/RS/Waybills/BuyerIncomeProducts', params);
        }

        saveIncomeReflection(model):angular.IPromise<any> {
            return this.httpPost('/RS/Waybills/SaveIncomeReflection', {IncomeTemplate:model});
        }

        getInvoices(data:{startDate:any; endDate:any; id:string}, state):angular.IPromise<any> {
            var params = {
                nm: 'Date1={0}&Date2={1}&jsconfig=dh:iso8601',
                pr: [data.startDate, data.endDate]
            };
            if (data.id) {
                params.nm += '&InvoiceNum={' + (params.pr.length) + '}';
                params.pr.push(data.id);
            }
            if (state === 'received') {
                return this.httpGet('/RS/Invoices/Buyer', params);
            }
            return this.httpGet('/RS/Invoices/Seller', params);
        }

        getInvoiceBooks(data:{D1:any; D2:any; Client:string; Numberin:string; Numberout:string; OperId:string; Cuser:string; Iud:string}):angular.IPromise<any> {
            var params = {
                nm: 'Date1={0}&Date2={1}',
                pr: [data.D1, data.D2]
            };
            if (data.Client) {
                params.nm += '&Client={' + (params.pr.length) + '}';
                params.pr.push(data.Client);
            }
            if (data.Numberin) {
                params.nm += '&Numberin={' + (params.pr.length) + '}';
                params.pr.push(data.Numberin);
            }
            if (data.Numberout) {
                params.nm += '&Numberout={' + (params.pr.length) + '}';
                params.pr.push(data.Numberout);
            }
            if (data.Iud) {
                params.nm += '&Iud={' + (params.pr.length) + '}';
                params.pr.push(data.Iud);
            }
            if (data.OperId) {
                params.nm += '&OperId={' + (params.pr.length) + '}';
                params.pr.push(data.OperId);
            } 
            return this.httpGet('/RS/Invoices/Books', params);
        }

        importFNumber(fromDate, toDate, recieved) {
            var params = {Date1: fromDate, Date2: toDate};

            if (recieved) {
                return this.httpPatch('/RS/Invoices/ImportRecivedFNumber', params);
            }

            return this.httpPatch('/RS/Invoices/ImportFNumber', params);
        }

        getPayerTypeFromBookID(bookID): angular.IPromise<any> {
            return this.httpGet('/RS/getPayerTypeFromBookID/' + bookID);
        }

        createInvoice(bookID): angular.IPromise<any> {
            return this.httpPost('/RS/Invoices/CreateInvoice', {BookId:bookID});
        }

        changeInvoiceStatus(id, status): angular.IPromise<any> {
            return this.httpPatch('/RS/Invoices/ChangeInvoiceStatus', {ID:id, Status:status});
        }

        deleteLocalInvoice(bookID, MofInvoiceNum): angular.IPromise<any> {
            if (MofInvoiceNum) {
                return this.httpDelete('/RS/Invoices/DeleteLocalInvoiceInfo/ByInvoiceNum/' + MofInvoiceNum);
            }
            return this.httpDelete('/RS/Invoices/DeleteLocalInvoiceInfo/ByBook/' + bookID);
        }

        deleteInvoice(invoiceId): angular.IPromise<any> {
            return this.httpDelete('/RS/Invoices/DeleteInvoice/' + invoiceId);
        }

        getInvoiceView(invoiceId): angular.IPromise<any> {
            return this.httpGet('/RS/Invoices/' + invoiceId + '/GetInvoiceView');
        }

        getKInvoiceView(invoiceId): angular.IPromise<any> {
            return this.httpGet('/RS/Invoices/' + invoiceId + '/GetKInvoice');
        }

        getReturnedWaybills(invoiceId): angular.IPromise<any> {
            return this.httpGet('/RS/Invoices/' + invoiceId + '/GetReturnedWaybills');
        }

        mergeGoodsByReturnedWBResponse(dets, bookIds): angular.IPromise<any> {
            return this.httpPost('/RS/Invoices/MergeGoodsByReturnedWB', {Dets:dets, BookIds:bookIds});
        }

        makeKInvoice(invoiceId, kType): angular.IPromise<any> {
            return this.httpPost('/RS/Invoices/MakeKInvoice', {InvoiceId: invoiceId, KType:kType});
        }

        saveInvoiceDets(invoiceId, dets, nos): angular.IPromise<any> {
            return this.httpPost('/RS/Invoices/SaveInvoiceDets', {InvoiceId: invoiceId, NewData:true, Dets:dets, Nos: nos});
        }

        getError(code) {
            var toaster = Core.Utils.Helper.getServiceFromJs(Globals.toaster);
            this.getWBMOFDicionary(5).then(result => {
                for (var i = 0; i < result.Result.length; i++) {
                    if (result.Result[i].ID == code) {
                        return toaster.pop('error', result.Result[i].Name);
                    }
                }
            });
        }
    }
}
