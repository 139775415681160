/// <reference path='../_reference.ts' />
module Apex.RsService.Controllers {
    import IInvoice = Apex.RsService.Interfaces.IInvoice;
    import IAuthMofUser = Apex.RsService.Interfaces.IAuthMofUser;
    export interface IInvoices {
        Invoices?: Array<IInvoice>;
    }

    export class Invoices extends Core.Controller {

        public static $inject = [
            Globals.scope, 'rSService', 'dialogs', '$filter', '$rootScope', Globals.translate, Globals.toaster
        ];

        constructor(private $scope: any, private rSService: Services.RS, private dialogs: any, private $filter: any, private $rootScope, private $translate: any, private toaster: any) {

            super(arguments, Invoices.$inject);

            $scope.events = this;

            var invoices: IInvoices = {
                Invoices: []
            };

            $scope.invoices = invoices;

            $scope.auth = {
                authenticated: false
            };

            $scope.mofDictionary = {
                kTypes: []
            };


            this.getMofDicionary();


            $scope.invoicesGridOptions = this.invoicesGridOptions($scope.invoices.Invoices);


            $scope.status = {
                open: false
            };

            var yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);

            $scope.filters = {
                dateBetween: {
                    fromDate: yesterday,
                    toDate: new Date()
                },
                statuses: [0, 1, 2],
                state: 'sent'
            };

            $scope.changeSelectedInv = function (selectedInv) {
                var focusedRowModel: IInvoice = selectedInv;
                $scope.selectedInvoice = focusedRowModel;
            };

            var timeoutCode;
            var delayInMs = 2000;

            $scope.$watch('filters', (filterVal) => {

                if (!filterVal[1])
                    return;

                clearTimeout(timeoutCode);  //does nothing, if timeout already done

                timeoutCode = setTimeout(function () {
                    $scope.events.reloadGridData(filterVal[0])
                }, delayInMs);
            }, true);

        }

        importFNumber() {
            var dlg = this.dialogs.confirm(this.$translate.instant('Attention'), this.$translate.instant('confirmImportFNumbers'));
            var filter = this.$scope.filters;
            dlg.result.then(btn => {
                this.rSService.importFNumber(filter.dateBetween.fromDate, filter.dateBetween.toDate, filter.state === 'received').then(results => {
                    this.doAfterAction(results, "ImportFNumber");
                });
            });
        }

        refresh() {
            this.$scope.invoicesGridOptions.gridView.showLoading();
            this.$scope.events.reloadGridData(this.$scope.filters);
        }

        send(selectedInvoice) {
            if (typeof selectedInvoice === "undefined")
                return;
            var dlg = this.dialogs.confirm(this.$translate.instant('Attention'), this.$translate.instant('sendInvoice'));
            dlg.result.then(btn => {
                this.rSService.changeInvoiceStatus(selectedInvoice.InvoiceID, selectedInvoice.Status + 1).then(results => {
                    this.doAfterAction(results, "InvoiceSend");
                });
            });
        }

        deleteInvoice(selectedInvoice) {
            if (typeof selectedInvoice === "undefined")
                return;
            var dlg = this.dialogs.confirm(this.$translate.instant('Attention'), this.$translate.instant('deleteConfirmation'));
            dlg.result.then(btn => {
                this.rSService.deleteInvoice(selectedInvoice.InvoiceID).then(results => {
                    this.doAfterAction(results, "InvoiceDelete");
                });
            });
        }

        correct(kType) {
            var selectedInvoice = this.$scope.selectedInvoice;
            if (typeof selectedInvoice === "undefined")
                return;

            var filter = this.$scope.filters;

            var formData: any = {
                Invoice: {},
                correcting: true
            };

            this.rSService.makeKInvoice(selectedInvoice.InvoiceID, kType.ID).then((result) => {
                if (result.Error === 202) {
                    this.toaster.pop('warning', this.$translate.instant('Attention'), this.$translate.instant('invoiceCorrectFailed'));
                    return;
                }
                formData.Invoice = result.Invoice;
                this.reloadGridData(filter);
                var dlg = Core.Utils.ModalForm.openModalForm("modal.invoice.html", Controllers.InvoiceEdit.getName(), formData, Core.Utils.ModalSize.Medium);
                dlg.result.then(result => {
                    this.reloadGridData(filter);
                });
            });
        }

        async details(selectedInvoice): Promise<void> {
            if (typeof selectedInvoice === "undefined")
                return;

            let filter = this.$scope.filters;

            let formData = new InvoiceEditData();
            
            
            let resp = await this.rSService.getInvoiceView(selectedInvoice.InvoiceID);
            if (resp != null && resp.Invoice != null) {
                formData.invoice = resp.Invoice;
                var dlg = Core.Utils.ModalForm.openModalForm("modal.invoice.html", Controllers.InvoiceEdit.getName(), formData, Core.Utils.ModalSize.Medium);
                dlg.result.then(result => {
                    if (typeof result !== "undefined")
                        this.reloadGridData(filter);
                });
            }
        }

        doAfterAction(results, actionName) {
            var filter = this.$scope.filters;
            if (results && results.Error === 0) {
                delete this.$scope.selectedInvoice;
                this.toaster.pop('success', this.$translate.instant('Info'), this.$translate.instant('success' + actionName));
                this.reloadGridData(filter);
            } else if (!results.success) {
                delete this.$scope.selectedInvoice;
                this.toaster.pop('error', "Error", this.$translate.instant('fail' + actionName));
            }
        }

        reloadGridData(filter) {
            var data = {
                startDate: new Date(filter.dateBetween.fromDate.setHours(0, 0, 0, 0)),
                endDate: new Date(filter.dateBetween.toDate.setHours(23, 59, 59, 999)),
                id: null
            };

            if (filter.id) {
                data.id = filter.id;
            }

            this.rSService.getInvoices(data, filter.state).then((result) => {
                this.$scope.invoicesGridOptions.dataSource.data(result.Invoices);
            });
            this.$scope.invoicesGridOptions.gridView.hideLoading();
        }

        private templates: Array<Core.Interfaces.IGridColumnTemplate> = [
            {
                fieldName: 'Status',
                template: '<div class="text-center">' +
                '<span class="fa fa-info" tooltip-placement="right" tooltip="{{\'invoiceStatus_\' + Status | translate}}"> </span>' +
                '</div>'
            },
            {
                fieldName: 'Was_Ref',
                template: '<div class="text-center" ng-if="dataItem.Was_Ref"><span class="fa fa-check"></span></div>'
            }
        ];

        private invoicesGridColumns: Array<Core.Interfaces.IGridColumn> = [
            { field: 'Status', width: 50 },
            { field: 'Was_Ref', titleTranslated: 'Was_Ref', width: 50 },
            { field: 'FSeries', titleTranslated: 'FSeries', width: 90 },
            { field: 'FNumber', titleTranslated: 'FNumber', width: 90 },
            { field: 'OperationDate', titleTranslated: 'OperationDate', width: 120, format: Core.Kendo.Format.Date },
            { field: 'RegDate', titleTranslated: 'RegDate', width: 120, format: Core.Kendo.Format.DateTime },
            {
                field: 'Amount', titleTranslated: 'amount', width: 90, format: Core.Kendo.Format.Price2,
                footerTemplate: '<div  style="float:right"><span translate=sum> </span> #= kendo.toString(data && data.Amount && data.Amount.sum ? parseFloat(data.Amount.sum).toFixed(2) : 0) #</div>'
            },
            { field: 'OrgName', titleTranslated: 'buyer', width: 120 },
            { field: 'OrgSn', titleTranslated: 'SN', width: 100 },
            { field: 'Notes', titleTranslated: 'Notes' },
        ];

        private invoicesGridOptions(dataSource: Array<IInvoices>): Components.IApxGrid {
            var self = this; 

            var gridOptions: Components.IApxGrid ={
                settings: {
                    pageable: true,
                    virtualScroll: true
                },
                columns: this.invoicesGridColumns,
                templates: this.templates,
                gridEvents: [this.getChangeEvent()], 
                parentScope: this.$scope,
                dataSource: new kendo.data.DataSource({
                    data: [],
                    schema: {
                        model: {
                            fields: {
                                Status: { type: 'boolean' },
                                Was_Ref: { type: 'string' },
                                FSeries: { type: 'string' },
                                FNumber: { type: 'number' },
                                OperationDate: { type: 'date' },
                                RegDate: { type: 'date' },
                                Amount: { type: 'number' },
                                OrgName: { type: 'string' },
                                OrgSn: { type: 'string' },
                                Notes: { type: 'string' },
                            }
                        }
                    },
                    pageSize: 50
                }) 
            };

            return gridOptions;
        }

        private getChangeEvent(): Components.IEvent {
            var changeEventType: Components.EventType = Components.EventType.Change;
            var addEvent: Components.IEvent =
                {
                    eType: changeEventType,
                    eFunction: this.changeEventFunctionality
                };
            return addEvent;
        }

        changeEventFunctionality = (e) => {
            Core.Utils.Helper.safeApply(this.$scope, this.$scope.changeSelectedInv(e.sender.dataItem(e.sender.select())))
        };

        getMofDicionary() {
            this.rSService.getWBMOFDicionary(9).then(result => {
                this.$scope.mofDictionary.kTypes = result.Result;
            });
        }
    }
}
