/// <reference path='./_reference.ts' />
module Apex.RsService {
    'use strict';

    var apxDictionary = new Core.Module('rsservice', ['apex.webUI.account', 'apex.webUI.dictionary'], RsService);
    var routes: Array<Core.Interfaces.IRoute> =
        [
            {
                state: 'waybill',
                url: '^/waybill',
                template: '<ui-view/>',
                navigation: { caption: 'rsgeServices', navigationClass: 'md md-share' },
                childRoutes: [
                    {
                        state: 'invoices',
                        url: '/invoices',
                        template: '<ui-view/>',
                        navigation: {
                            caption: 'invoices'
                        },
                        childRoutes: [
                            {
                                state: 'invoiceBooks',
                                url: '/invoiceBooks',
                                controller: RsService.Controllers.InvoiceBooks.getName(),
                                templateUrl: 'invoiceBooks.html',
                                navigation: {
                                    caption: 'BookList'
                                }
                            },
                            {
                                state: 'invoicesList',
                                url: '/invoicesList',
                                controller: RsService.Controllers.Invoices.getName(),
                                templateUrl: 'invoices.html',
                                navigation: {
                                    caption: 'InvoiceList'
                                }
                            }
                        ],
                    },
                    {
                        state: 'list',
                        url: '/list',
                        template: '<ui-view/>',
                        navigation: {
                            caption: 'WaybillList'
                        },
                        childRoutes: [
                            {
                                state: 'waybillBooks',
                                url: '/waybillBooks',
                                controller: RsService.Controllers.WaybillBooks.getName(),
                                templateUrl: 'waybillBooks.html',
                                navigation: {
                                    caption: 'BookList'
                                }
                            },
                            {
                                state: 'waybillList',
                                url: '/waybillList',
                                controller: RsService.Controllers.Waybills.getName(),
                                templateUrl: 'waybills.html',
                                navigation: {
                                    caption: 'WaybillList'
                                }
                            }
                        ]
                    },
                    {
                        state: 'waybillGoodsRef',
                        url: '/waybillref',
                        controller: RsService.Controllers.WaybillGoodsRef.getName(),
                        templateUrl: 'waybill.goods.ref.html',
                        navigation: {
                            caption: 'oilinvoice'
                        }
                    },
                    {
                        state: 'suppliers',
                        url: '/suppliers',
                        template: '<div> It is suppliers </div>',
                        navigation: {
                            caption: 'suppliers'
                        }
                    },
                    {
                        state: 'comparing',
                        url: '/comparing',
                        template: '<div> It is compareDocuments </div>',
                        navigation: {
                            caption: 'compareDocuments'
                        }
                    }
                ]
            }
        ];


    
    apxDictionary.regRoutes(routes, 'apex');
}