/// <reference path="./../_reference.ts"/>
module Apex.RsService.Interfaces {
    "use strict";
    export interface IIncomeDocTemplate {
        OperID?: string;
        Docs_id?: string;
        DDate?: Date;
        WaybillNum?: string;
        WaybillID?: number;
        DB?: Account.Interfaces.IAccountInfo | string;
        CR?: Account.Interfaces.IAccountInfo | string;
        SupplierSN?:string;
        SupplierID?: number;
        Products?: Array<IIncomeProduct>;
    }

    export interface IIncomeProduct {
        WbDetailId?: number;
        WbProductCode?: string;
        WbProductName?: string;
        WbProductUnit?: string;
        WbVatType?: number;
        ProdPPId?: string;
        BCode?: string;
        ProductsNu?: string;
        ProducerNu?: string;
        InCode?: string;
        Unit?: string;
        Vat?: number;
        ItCount?: number;
        Price?: number;
        Amount?: number;
        LastCost?: number;
        SalePrice?: number;
        VatAmount?: number;
        AmountWithoutVat?: number;
        LifeDate?:Date;
        UnicCode?:string;
        UpdateProdPPRef?:boolean;
        Product?:any;
        updating?:boolean
    }
} 